.modal
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 2000
  display: none
  align-items: center
  justify-content: center
  .subtitle
    margin-bottom: 20px
  &--static
    overflow: auto
    .modal__wrapper
      position: relative
      display: block
      padding: 30px 0
  &__subtitle
    margin-bottom: 20px
    color: $gray
    font-size: 16px
    text-align: center
    a
      color: $blue
      text-decoration: underline
      &:hover
        text-decoration: none
  .modal__close
    position: absolute
    right: 0
    bottom: 100%
    margin-bottom: 10px
    color: $white
    font-size: 14px
    cursor: pointer
    text-transform: uppercase
  &__wrapper
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    display: flex
    align-items: center
    justify-content: center
  &__content
    position: relative
    width: 100%
    z-index: 2100
    &--white
      background-color: $white
    &--big
      max-width: 80%
      padding: 50px 20px
      margin: 0 10%
      border-radius: 50px
    &--medium
      max-width: 332px
      padding: 30px 30px 20px
      border-radius: 12px
  &__overlay
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 2050
    background-color: rgba(4, 12, 22, 0.9)
    &--transparent
      background-color: transparent
  &__icon
    margin-bottom: 20px
    text-align: center
    cursor: pointer
    .icon
      position: relative
      display: block
      width: 20px
      height: 20px
      margin: 0 auto
      &:before,
      &:after
        content: ''
        position: absolute
        top: 10px
        left: 0
        right: 0
        height: 2px
        background-color: $gray
      &:before
        transform: rotate(45deg)
      &:after
        transform: rotate(-45deg)
    .text
      color: $gray
      font-size: 14px
      text-transform: uppercase
  &__form
    &.center
      .field__label
        width: 100%
        text-align: center
      .field__error
        text-align: center
      input,
      textarea
        text-align: center
  &__pay
    margin-bottom: 15px
    color: $dark-gray
    font-size: 16px
    font-weight: bold
    text-align: center
    text-transform: uppercase
    &--normal
      font-weight normal
      text-transform none
      color unset
      margin-bottom 6px
  .video-source
    width: 100%
    height: 250px
  &__cancel
    color $red
    text-align center
  &__contact
    margin-top 20px
    text-align center

@media(min-width: $xs)
  .modal
    .video-source
      height: 350px
    &__content
      &--big
        max-width: 420px

@media(min-width: $sm)
  .modal
    .video-source
      height: 450px
    &__content
      &--big
        max-width: 550px
        margin: 0 auto
    &__form
      max-width: 395px
      margin: 0 auto
    &__icon
      margin-bottom: 30px

@media(min-width: $md)
  .modal
    .video-source
      height: 550px
    &__content
      &--big
        max-width: 755px
        width: 100%
        margin: 0 auto
        padding: 75px 115px 130px
        border-radius: 110px
    &__icon
      margin-bottom: 40px

@media(min-width: $lg)
  .modal
    .video-source
      height: 650px
