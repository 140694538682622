.dropdown
  max-width: 165px
  padding: 15px 0px 15px 10px
  .menu
    &__list
      & > li
        & > a
          font-size: 12px
          font-weight: normal
        &.active
          & > a
            color: $blue

@media(min-width: $md)
  .dropdown
    position: absolute
    top: 100%
    left: 50%
    max-width: none
    width: 190px
    padding: 15px 0 0
    margin-left: -95px
    opacity: 0
    visibility: hidden
    transform: translateY(15px)
    transition: .3s
    .menu
      &__list
        position: relative
        flex-direction: column
        padding: 25px 0px
        border-radius: 13px
        background-color: $white
        box-shadow: 0 0 24px rgba(0, 53, 133, 0.15)
        &:before
          content: ''
          position: absolute
          left: 50%
          bottom: 100%
          display: block
          width: 0
          height: 0
          margin-left: -6px
          border-bottom: 6px solid $white
          border-top: 6px solid transparent
          border-left: 6px solid transparent
          border-right: 6px solid transparent
        & > li
          width: 100%
          margin: 0 0 15px
          text-align: center
          transition: .3s
          &:hover
            background-color: $dark-white
            & > a
              font-weight: normal
          & > a
            display: inline-block
            width: 100%
            padding: 5px 20px
            font-size: 15px
            line-height: 20px
            text-transform: none
          &.active
            background-color: $dark-white
            & > a
              color: $dark-blue
              font-weight: normal