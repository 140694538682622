.info-block
  max-width: 360px
  width: 100%
  margin-left: auto
  margin-right: auto
  margin-bottom: 50px
  &:last-child
    margin-bottom: 0
  .subtitle
    margin-bottom: 10px
  &__item
    position: relative
    &.has-subitem
      padding: 45px 0 25px
      margin-bottom: 25px
      .info-block__image
        margin-bottom: 0
  &__subitem
    position: absolute
    top: 0
    left: 50%
    bottom: 0
    max-width: 275px
    width: 100%
    padding: 15px 40px
    text-align: center
    border: 1px solid $blue
    background-color: rgba(56, 179, 190, 0.04)
    transform: translateX(-50%)
    &:before
      top: 0
      left: 0
      transform: translate(-50%, -50%)
    &:after
      top: 0
      right: 0
      transform: translate(50%, -50%)
  &__subcontent
    &:before
      left: 0
      bottom: 0
      transform: translate(-50%, 50%)
    &:after
      right: 0
      bottom: 0
      transform: translate(50%, 50%)
    p
      color: $blue
      font-size: 16px
      font-weight: 700
    img
      position: absolute
      top: -25px
      left: 50%
      display: block
      max-width: 110px
      transform: translateX(-50%)
  &__subitem,
  &__subcontent
    &:before,
    &:after
      content: ''
      position: absolute
      display: block
      width: 5px
      height: 5px
      border-radius: 50%
      border: 5px solid $blue
      background-color: $white
  &__content
    text-align: center
  &__image
    position: relative
    z-index: 10
    margin-bottom: 25px
    text-align: center
    img
      display: inline-block
      max-width: 100%
  &__text
    color: $gray
    font-size: 16px
    line-height: 30px
    font-family: $lato
  &__subtext
    margin-bottom: 10px
    color: $gray
  &__play
    position: absolute
    top: 50%
    left: 50%
    display: inline-block
    width: 65px
    height: 65px
    border-radius: 50%
    cursor: pointer
    background-color: $blue
    transform: translate(-50%, -50%)
    img
      display: block
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-40%, -50%)
  .btn
    margin-top: 15px

@media(min-width: $sm)
  .info-block
    display: flex
    align-items: center
    justify-content: space-between
    max-width: 100%
    margin-bottom: 75px
    &:last-child
      margin-bottom: 0
    .subtitle
      margin-bottom: 25px
      line-height: 45px
    &--reverse
      flex-direction: row-reverse
      .info-block__item
        &.has-subitem
          padding: 40px 30px 40px 0
          margin-bottom: 0
          text-align: right
      .info-block__subitem
        left: auto
        right: 0
        text-align: right
        padding-left: 20px
        padding-right: 30px
      .info-block__content
        padding-left: 0
        padding-right: 25px
    &__item
      width: 50%
      &.has-subitem
        padding: 40px 0 40px 30px
        margin-bottom: 0
        text-align: left
    &__subitem
      left: 0
      text-align: left
      padding-left: 30px
      padding-right: 20px
      transform: translateX(0%)
    &__image
      display: inline-block
      margin-bottom: 0
    &__content
      padding-left: 25px
      text-align: left
    .btn
      margin-top: 25px

@media(min-width: $md)
  .info-block
    margin-bottom: 160px
    &:last-child
      margin-bottom: 0
    &--reverse
      .info-block__item
        &.has-subitem
          padding: 50px 40px 50px 0
      .info-block__subitem
        padding-left: 20px
        padding-right: 40px
      .info-block__content
        padding-left: 20px
        padding-right: 50px
    &__item
      &.has-subitem
        padding: 50px 0 50px 40px
    &__subitem
      padding-top: 20px
      padding-left: 40px
      padding-right: 20px
    &__subtext
      margin-bottom: 15px
    &__content
      padding-left: 50px
      padding-right: 20px
    .btn
      margin-top: 30px


@media(min-width: $lg)
  .info-block
    &--reverse
      .info-block__image
        margin-left: 55px
        margin-right: 0
      .info-block__content
        padding-left: 0
        padding-right: 0
    &__image
      margin-left: 0
      margin-right: 55px
    &__content
      max-width: 430px
      width: 100%
      padding-left: 0
      padding-right: 0