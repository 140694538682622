div.plan-features
  &__mobile-only
    display: none
  &__desktop-only
    display: flex

.plan-features
  max-width: 350px
  margin: 0 auto 40px
  &__plan
    &-name
      font-size: 18px
      margin-bottom: 20px
    &-price
      font-size: 24px
      font-weight: bold
      margin-bottom: 50px
      &--sub
        font-size: 18px
        vertical-align: text-top
      &-description
        font-size: 12px
        font-weight: normal
  &__features
    margin-bottom: 40px
    padding-left: 50px
    height: 20px
  &:last-child
    margin-bottom: 0
  &.annual
    display: none
  &__title
    margin-bottom: 100px
    color: $dark-blue
    font-size: 22px
    font-weight: 900
  &__subtitle
    font-size 18px
    margin-bottom: 45px
  &__text
    margin-bottom: 25px
    color: $gray
    font-size: 16px
    line-height: 30px
  ul
    align-self: center
    li
      position: relative
      margin-bottom: 25px
      &:last-child
        margin-bottom: 0
      p
        margin-bottom: 25px
        color: $dark-blue
        font-weight: 700
        line-height: 20px
        &:last-child
          margin-bottom: 0

@media(min-width: $sm)
  .plan-features
    display: flex
    flex-direction: column
    max-width: 33.33%
    padding: 0 5px
    margin-left: 0
    margin-right: 0
    margin-bottom: 0
    &__title
      margin-bottom: 100px
    &__text
      margin-bottom: 30px

@media(min-width: $md)
  .plan-features
    &__title
      font-size: 22px
    &__text
      margin-bottom: 45px
    ul
      li
        margin-bottom: 40px
        p
          margin-bottom: 40px

@media(min-width: $sm)
  .plan-features
    &__plan-price
      margin-bottom: 122px
    ul
      li
        margin-bottom: 40px

@media(max-width: $sm)
  div.plan-features
    &__mobile-only
      display: flex
    &__desktop-only
      display: none
  .plan-features
    &__title
      margin-bottom: 40px
      flex-basis: 100%
    &__features
      padding-left: 0
    &__plan
      flex: 1
    ul
      li
        margin-bottom: 40px
        margin-left: 15px
        white-space: nowrap
      &:first-child
        margin-top: 66px
    &__button-list
      margin-bottom: 60px
      flex-basis: 100%
    &__button
      border 1px solid $green
      border-radius: 15px
      background-color: $white
      padding: 7px 12px
      margin-right: 5px
      &:last-child
        margin-right: 0
      &--active
        color: $white
        background-color: $green
    &__plan-title
      flex-basis: 100%
      &.annual
        display: none
