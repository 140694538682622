.fullvideo
  display: flex
  align-items: center
  justify-content: center
  min-height: 300px
  padding: 45px 0
  background-size: cover
  background-position: 50% 50%
  &--mask
    position: relative
    &:before
      content: ''
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      z-index: 20
      display: block
      background-color: $banner-mask
    .fullvideo__content
      position: relative
      z-index: 30
  &__content
    position: relative
    z-index: 10
    text-align: center
    &--light
      color: $white
    &--dark
      color: $dark-blue
  &__title
    margin-bottom: 20px
    font-size: 24px
    line-height: 32px
    &:last-child
      margin-bottom: 0
    &--normal
      font-weight: 400
    &--bold
      font-weight: 900
  &__subtitle
    display: inline-block
    width: 100%
    margin-bottom: 10px
    font-size: 16px
    line-height: 28px
  &__text
    margin-bottom: 20px
    font-size: 16px
    line-height: 28px
    &:last-child
      margin-bottom: 0
  &__play
    position: relative
    display: inline-block
    width: 65px
    height: 65px
    margin-top: 10px
    border-radius: 50%
    cursor: pointer
    background-color: $blue
    img
      display: block
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-40%, -50%)

@media(min-width: $sm)
  .fullvideo
    min-height: 400px
    padding: 60px 0
    &__content
      max-width: 650px
      margin: 0 auto
    &__title
      font-size: 30px
      line-height: 40px
    &__subtitle
      font-size: 18px
    &__text
      font-size: 18px
      line-height: 30px

@media(min-width: $md)
  .fullvideo
    min-height: 600px
    &__content
      max-width: 800px

@media(min-width: $lg)
  .fullvideo
    min-height: 800px
    &__content
      max-width: 980px
    &__title
      margin-bottom: 25px
    &__subtitle
      font-size: 20px
      line-height: 40px
    &__text
      font-size: 20px
      line-height: 40px