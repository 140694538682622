@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Regular.eot");
  src: local('Lato Regular'), local('Lato-Regular'), url("../fonts/Lato-Regular.eot?#iefix") format('embedded-opentype'), url("../fonts/Lato-Regular.woff2") format('woff2'), url("../fonts/Lato-Regular.woff") format('woff'), url("../fonts/Lato-Regular.ttf") format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Bold.eot");
  src: local('Lato Bold'), local('Lato-Bold'), url("../fonts/Lato-Bold.eot?#iefix") format('embedded-opentype'), url("../fonts/Lato-Bold.woff2") format('woff2'), url("../fonts/Lato-Bold.woff") format('woff'), url("../fonts/Lato-Bold.ttf") format('truetype');
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Heavy.eot");
  src: local('Lato Heavy'), local('Lato-Heavy'), url("../fonts/Lato-Heavy.eot?#iefix") format('embedded-opentype'), url("../fonts/Lato-Heavy.woff2") format('woff2'), url("../fonts/Lato-Heavy.woff") format('woff'), url("../fonts/Lato-Heavy.ttf") format('truetype');
  font-style: normal;
  font-weight: 800;
  text-rendering: optimizeLegibility;
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Black.eot");
  src: local('Lato Black'), local('Lato-Black'), url("../fonts/Lato-Black.eot?#iefix") format('embedded-opentype'), url("../fonts/Lato-Black.woff2") format('woff2'), url("../fonts/Lato-Black.woff") format('woff'), url("../fonts/Lato-Black.ttf") format('truetype');
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
  font-display: swap;
}
@font-face {
  font-family: 'OpenSans';
  src: url("../fonts/OpenSans-Regular.eot");
  src: local('OpenSans Regular'), local('OpenSans-Regular'), url("../fonts/OpenSans-Regular.eot?#iefix") format('embedded-opentype'), url("../fonts/OpenSans-Regular.woff") format('woff'), url("../fonts/OpenSans-Regular.ttf") format('truetype'), url("../fonts/OpenSans-Regular.svg#OpenSans-Regular") format('svg');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap;
}
@font-face {
  font-family: 'OpenSans';
  src: url("../fonts/OpenSans-Bold.eot");
  src: local('OpenSans Bold'), local('OpenSans-Bold'), url("../fonts/OpenSans-Bold.eot?#iefix") format('embedded-opentype'), url("../fonts/OpenSans-Bold.woff") format('woff'), url("../fonts/OpenSans-Bold.ttf") format('truetype'), url("../fonts/OpenSans-Bold.svg#OpenSans-Bold") format('svg');
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-display: swap;
}
@font-face {
  font-family: 'LucidaGrande';
  src: url("../fonts/LucidaGrande-Bold.eot");
  src: local('LucidaGrande Bold'), local('LucidaGrande-Bold'), url("../fonts/LucidaGrande-Bold.eot?#iefix") format('embedded-opentype'), url("../fonts/LucidaGrande-Bold.woff") format('woff'), url("../fonts/LucidaGrande-Bold.ttf") format('truetype'), url("../fonts/LucidaGrande-Bold.svg#LucidaGrande-Bold") format('svg');
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-display: swap;
}
@font-face {
  font-family: 'icomoon';
  src: url("../icon-fonts/icomoon.eot?5etaug");
  src: url("../icon-fonts/icomoon.eot?5etaug#iefix") format('embedded-opentype'), url("../icon-fonts/icomoon.ttf?5etaug") format('truetype'), url("../icon-fonts/icomoon.woff?5etaug") format('woff'), url("../icon-fonts/icomoon.svg?5etaug#icomoon") format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
/* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
/* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-credit-cards:before {
  content: "\e902";
}
.icon-plan-info:before {
  content: "\e905";
}
.icon-check:before {
  content: "\e903";
}
.icon-team:before {
  content: "\e900";
}
.icon-document:before {
  content: "\e901";
}
.icon-card:before {
  content: "\e904";
}
.icon-pencil:before {
  content: "\e906";
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background-color: transparent;
  font-size: 100%;
}
html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  text-decoration: none;
  vertical-align: baseline;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline: 0;
}
a:focus {
  outline: thin dotted;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
td img {
  vertical-align: top;
}
input,
select,
button,
textarea {
  margin: 0;
  font-size: 100%;
}
button,
select {
  text-transform: none;
}
input[type="checkbox"],
input[type="radio"] {
  vertical-align: middle;
  margin: 0;
  padding: 0;
  box-sizing: border-box /* 1 */;
  padding: 0 /* 2 */;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button /* 2 */;
  cursor: pointer /* 3 */;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
input[type="search"] {
  -webkit-appearance: textfield /* 1 */;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box /* 2 */;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
  outline: 1px dotted ButtonText;
}
::-webkit-file-upload-button {
  -webkit-appearance: button /* 1 */;
  font: inherit /* 2 */;
}
textarea {
  overflow: auto /* 1 */;
  vertical-align: top /* 2 */;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
summary {
  display: list-item;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
nav ul {
  list-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
small {
  font-size: 80%;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  min-height: 100%;
  font-family: 'Lato';
}
html {
  scroll-behavior: smooth;
}
html.overflow {
  overflow: hidden;
}
li,
ol {
  list-style: none;
}
body {
  position: relative;
  word-wrap: break-word;
}
pre {
  white-space: pre-wrap;
}
a {
  text-decoration: none;
}
button {
  border: none;
  outline: none;
}
.disabled {
  pointer-events: none;
}
.is-disabled {
  text-decoration: none;
  pointer-events: none;
  opacity: 0.6;
}
.container {
  max-width: 1170px;
  width: 100%;
  padding: 0px 15px;
  margin: 0px auto;
}
.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto;
}
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
}
.row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.row.reverse {
  flex-direction: row-reverse;
}
.col.reverse {
  flex-direction: column-reverse;
}
.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  flex: 0 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}
.col-xs-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}
.col-xs-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}
.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%;
}
.col-xs-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}
.col-xs-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}
.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%;
}
.col-xs-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}
.col-xs-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}
.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%;
}
.col-xs-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}
.col-xs-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}
.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%;
}
.col-xs-offset-0 {
  margin-left: 0;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.start-xs {
  justify-content: flex-start;
}
.center-xs {
  justify-content: center;
}
.end-xs {
  justify-content: flex-end;
}
.top-xs {
  align-items: flex-start;
}
.middle-xs {
  align-items: center;
}
.bottom-xs {
  align-items: flex-end;
}
.around-xs {
  justify-content: space-around;
}
.between-xs {
  justify-content: space-between;
}
.first-xs {
  order: -1;
}
.last-xs {
  order: 1;
}
@media (min-width: 768px) {
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-0,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12 {
    flex: 0 0 auto;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-sm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-sm {
    justify-content: flex-start;
  }
  .center-sm {
    justify-content: center;
  }
  .end-sm {
    justify-content: flex-end;
  }
  .top-sm {
    align-items: flex-start;
  }
  .middle-sm {
    align-items: center;
  }
  .bottom-sm {
    align-items: flex-end;
  }
  .around-sm {
    justify-content: space-around;
  }
  .between-sm {
    justify-content: space-between;
  }
  .first-sm {
    order: -1;
  }
  .last-sm {
    order: 1;
  }
}
@media (min-width: 992px) {
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-0,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    flex: 0 0 auto;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-md-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-md {
    justify-content: flex-start;
  }
  .center-md {
    justify-content: center;
  }
  .end-md {
    justify-content: flex-end;
  }
  .top-md {
    align-items: flex-start;
  }
  .middle-md {
    align-items: center;
  }
  .bottom-md {
    align-items: flex-end;
  }
  .around-md {
    justify-content: space-around;
  }
  .between-md {
    justify-content: space-between;
  }
  .first-md {
    order: -1;
  }
  .last-md {
    order: 1;
  }
}
@media (min-width: 1200px) {
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    flex: 0 0 auto;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-lg-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-lg {
    justify-content: flex-start;
  }
  .center-lg {
    justify-content: center;
  }
  .end-lg {
    justify-content: flex-end;
  }
  .top-lg {
    align-items: flex-start;
  }
  .middle-lg {
    align-items: center;
  }
  .bottom-lg {
    align-items: flex-end;
  }
  .around-lg {
    justify-content: space-around;
  }
  .between-lg {
    justify-content: space-between;
  }
  .first-lg {
    order: -1;
  }
  .last-lg {
    order: 1;
  }
}
.blog-article__image {
  background-position: 100% 100%;
  background-size: cover;
}
.blog-article__image-content {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 540px;
  padding: 90px 0;
  margin-bottom: 40px;
}
.blog-article__title {
  max-width: 910px;
  margin-bottom: 40px;
  line-height: 1.2;
  color: #fff;
  font-size: 46px;
  font-weight: 600;
}
.blog-article .fixed-part-parent {
  padding-bottom: 20px;
}
.blog-article__content p {
  font-size: 18px;
  line-height: 1.7;
  font-weight: 300;
}
.blog-article__content img {
  margin: 20px auto;
}
.blog-article__content ol,
.blog-article__content ul {
  list-style: unset;
  margin-left: 10px;
}
.blog-article__content h1 {
  font-size: 46px;
  font-weight: 600;
}
.blog-article__content h2 {
  font-size: 38px;
  font-weight: 600;
}
.blog-article__content h3 {
  font-size: 32px;
  font-weight: 600;
}
.blog-article__content h4 {
  font-size: 24px;
  font-weight: 600;
}
.blog-article__content h5 {
  font-size: 18px;
  font-weight: 600;
}
.blog-article__content h6 {
  font-size: 16px;
  font-weight: 600;
}
@media screen and (max-width: 950px) {
  .blog-article__title {
    margin-bottom: 25px;
    font-size: 36px;
    line-height: 46px;
  }
}
.blog-section .wrap-search {
  width: 100%;
  transition: padding 0.4s;
  padding: 50px 15px 100px;
}
.blog-section .wrap-search__title {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  font-size: 36px;
  font-weight: 500;
  line-height: 46px;
  text-align: center;
}
.blog-section .wrap-search__search {
  opacity: 1;
  transform: translateY(0);
  transition: transform 0.4s, opacity 0.4s;
  transition-timing-function: cubic-bezier(0.5, 0, 0.15, 1);
  transform-origin: 50% 50%;
  transition-delay: 0.4s;
}
.blog-section .wrap-search__form {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  height: 55px;
  margin: 0 auto;
}
.blog-section .wrap-search--input {
  outline: 0;
  width: 100%;
  font-size: 18px;
  padding: 5px 10px 10px;
  color: #090909;
  font-weight: 500;
  height: 55px;
  line-height: 26px;
  background-color: #fff;
  border: none;
  border-bottom: 2px solid #38b3be;
}
.blog-section .wrap-search__btn {
  width: 55px;
  margin-left: 24px;
  background-color: #38b3be;
  border: none;
  text-align: center;
  outline: 0;
  display: flex;
  justify-content: center;
  line-height: 55px;
}
.blog-section .blog-component__header {
  margin-bottom: 24px;
}
.blog-section .blog-component__items {
  display: flex;
  margin-bottom: 14px;
  margin-left: -10px;
  margin-right: -10px;
}
.blog-section .blog-component__title {
  margin-top: 0;
  margin-bottom: 40px;
  color: #090909;
  font-size: 24px;
  font-weight: 500;
}
.blog-section .blog-component .label-component {
  display: inline-block;
  padding: 10px 25px 9px;
  margin-bottom: 20px;
  border: 1px solid;
  border-radius: 20px;
  color: #090909;
  text-decoration: none;
}
.blog-section .blog-component .label-orange {
  border-color: #60c556;
}
@media screen and (max-width: 950px) {
  .blog-section .blog-component-main .blog-item:nth-child(3) {
    display: none;
  }
  .blog-section .blog-component .blog-item {
    max-width: 47.6%;
    width: 47.6%;
  }
}
@media screen and (max-width: 640px) {
  .blog-section .blog-component-main .blog-item {
    width: 100%;
    max-width: 450px;
  }
  .blog-section .blog-component-main .blog-item:nth-child(3) {
    display: unset;
  }
  .blog-section .blog-component__items {
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
  }
}
.blog-section .blog-item {
  position: relative;
  max-width: 356px;
  width: 100%;
  height: 300px;
  margin: 10px;
}
.blog-section .blog-item .content {
  width: 100%;
  height: 100%;
}
.blog-section .blog-item__img {
  width: 60%;
  height: 100%;
  margin-left: 40%;
}
.blog-section .blog-item__content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.blog-section .blog-item__label {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 24px;
  letter-spacing: 1px;
}
.blog-section .blog-item--label-header {
  text-transform: uppercase;
  padding-top: 45%;
  padding-bottom: 10px;
  color: #38b3be;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
}
.blog-section .blog-item--label-content {
  line-height: 22px;
  color: #fff;
  font-size: 16px;
}
.blog-section .blog-item--overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 40%;
  height: 100%;
  z-index: 0;
  background-color: #000;
}
@media screen and (max-width: 1240px) {
  .blog-section .blog-item {
    height: auto;
    max-width: 356px;
  }
}
@media screen and (max-width: 640px) {
  .blog-section .blog-item {
    margin-left: 0;
    margin-right: 0;
  }
}
.blog-section__footer {
  margin-top: 40px;
}
.wrap-section__bg {
  background-color: #f3f3f3;
}
.blog-component {
  padding: 80px 0;
}
.blog-category {
  position: relative;
  padding: 60px 0 95px;
}
.blog-category--header {
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  margin-bottom: 90px;
  text-align: center;
  letter-spacing: 0;
  font-size: 36px;
  font-weight: 500;
  line-height: 46px;
}
.blog-category__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.blog-category .blog-component,
.blog-category__header {
  margin-top: 50px;
  margin-bottom: 30px;
}
.blog-category__categories {
  width: 65%;
}
.blog-category__categories-list {
  width: 33%;
  margin-top: 33px;
}
.blog-category__categories-list ul {
  list-style: none;
}
.blog-category .blog-sidebar {
  position: sticky;
  top: 10px;
}
.blog-category .blog-sidebar__title {
  display: block;
  line-height: 33px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 24px;
}
.blog-category .blog-sidebar__nav {
  margin-bottom: 20px;
}
.blog-category .blog-sidebar__list-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 20px 30px;
}
.blog-category .blog-sidebar--list-link-header {
  color: #090909;
  display: inline-block;
  margin-bottom: 15px;
}
.blog-category .blog-sidebar--list-link-content {
  color: #090909;
  display: block;
}
.blog-category .blog-sidebar__list-item {
  border-bottom: 1px solid #f3f3f3;
  position: relative;
  background-color: #fff;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #090909;
}
.blog-category .blog-sidebar__list-item:hover {
  background-color: #090909;
}
.blog-category .blog-sidebar__list-item:hover .blog-sidebar--list-link-header,
.blog-category .blog-sidebar__list-item:hover .blog-sidebar--list-link-content {
  color: #fff;
}
.blog-category .blog-component__items {
  flex-wrap: wrap;
}
.blog-category .active {
  background-color: #090909;
  color: #fff;
}
.blog-category .active .blog-sidebar--list-link-header,
.blog-category .active .blog-sidebar--list-link-content {
  color: #fff;
}
@media screen and (max-width: 1240px) {
  .blog-category .blog-item {
    width: 47%;
    max-width: unset;
    height: auto;
  }
}
@media screen and (max-width: 1124px) {
  .blog-category .blog-item {
    width: 46%;
    height: auto;
  }
}
@media screen and (max-width: 991px) {
  .blog-category__header {
    margin-bottom: 24px;
  }
  .blog-category__content {
    flex-direction: column;
  }
  .blog-category__categories {
    width: 100%;
    order: 2;
    margin-top: 30px;
  }
  .blog-category__categories-list {
    width: 100%;
    order: 1;
    margin-top: 0;
  }
  .blog-category .blog-sidebar {
    position: relative;
  }
  .blog-category .blog-sidebar__list-item:after {
    content: '';
    border: 1px solid #9b9b9b;
    position: absolute;
    top: 50%;
    width: 14px;
    height: 14px;
    margin-top: -8px;
    right: 15px;
    border-radius: 50%;
  }
  .blog-category .blog-sidebar__list-item:hover {
    background-color: #fff;
  }
  .blog-category .blog-sidebar__list-item:hover .blog-sidebar--list-link-header,
  .blog-category .blog-sidebar__list-item:hover .blog-sidebar--list-link-content {
    color: #090909;
  }
  .blog-category .active {
    background-color: #fff;
  }
  .blog-category .active .blog-sidebar--list-link-header,
  .blog-category .active .blog-sidebar--list-link-content {
    color: #090909;
  }
  .blog-category .active:before {
    content: '';
    position: absolute;
    top: 50%;
    background: #090909;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    right: 18px;
    border-radius: 50%;
  }
  .blog-category .blog-item {
    width: 47.6%;
    height: auto;
  }
}
@media screen and (max-width: 844px) {
  .blog-category .blog-item {
    width: 47.4%;
  }
}
@media screen and (max-width: 780px) {
  .blog-category .blog-item {
    width: 47.3%;
  }
}
@media screen and (max-width: 750px) {
  .blog-category .blog-item {
    width: 47.1%;
  }
  .blog-category .blog-sidebar__list-link {
    padding-right: 50px;
  }
}
@media screen and (max-width: 700px) {
  .blog-category .blog-item {
    width: 47%;
  }
}
@media screen and (max-width: 677px) {
  .blog-category .blog-item {
    width: 46.6%;
  }
}
@media screen and (max-width: 640px) {
  .blog-category .blog-component__items {
    justify-content: center;
  }
  .blog-category .blog-item {
    max-width: 450px;
    width: 100%;
  }
}
.cms-ready .header:not(.header--relative) {
  top: 46px;
}
.cms-ready .header:not(.header--relative)__content {
  top: 46px;
}
.cms-ready .select2-dropdown {
  margin-top: 46px;
}
div.cms .cms-messages {
  display: none !important;
}
.dropdown {
  max-width: 165px;
  padding: 15px 0px 15px 10px;
}
.dropdown .menu__list > li > a {
  font-size: 12px;
  font-weight: normal;
}
.dropdown .menu__list > li.active > a {
  color: #38b3be;
}
@media (min-width: 992px) {
  .dropdown {
    position: absolute;
    top: 100%;
    left: 50%;
    max-width: none;
    width: 190px;
    padding: 15px 0 0;
    margin-left: -95px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    transition: 0.3s;
  }
  .dropdown .menu__list {
    position: relative;
    flex-direction: column;
    padding: 25px 0px;
    border-radius: 13px;
    background-color: #fff;
    box-shadow: 0 0 24px rgba(0,53,133,0.15);
  }
  .dropdown .menu__list:before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 100%;
    display: block;
    width: 0;
    height: 0;
    margin-left: -6px;
    border-bottom: 6px solid #fff;
    border-top: 6px solid transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
  .dropdown .menu__list > li {
    width: 100%;
    margin: 0 0 15px;
    text-align: center;
    transition: 0.3s;
  }
  .dropdown .menu__list > li:hover {
    background-color: #f1f6fb;
  }
  .dropdown .menu__list > li:hover > a {
    font-weight: normal;
  }
  .dropdown .menu__list > li > a {
    display: inline-block;
    width: 100%;
    padding: 5px 20px;
    font-size: 15px;
    line-height: 20px;
    text-transform: none;
  }
  .dropdown .menu__list > li.active {
    background-color: #f1f6fb;
  }
  .dropdown .menu__list > li.active > a {
    color: #3c405a;
    font-weight: normal;
  }
}
.footer-top {
  padding: 30px 0;
  text-align: center;
  background-color: #3c405a;
  border-bottom: 2px solid #fff;
  padding-bottom: 15px;
}
.footer-top__logo {
  margin-bottom: 30px;
}
.footer-top__logo img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
.footer-top__item {
  margin-bottom: 30px;
}
.footer-top__item:last-child {
  margin-bottom: 0;
}
.footer-top__title {
  margin-bottom: 20px;
  color: #fff;
  font-size: 20px;
}
.footer-top__link {
  color: #fff;
}
.footer-top__menu li {
  margin-bottom: 15px;
}
.footer-top__menu li:last-child {
  margin-bottom: 0;
}
.footer-top__menu a {
  color: #fff;
  font-size: 16px;
  transition: 0.3s;
}
.footer-top__menu a:hover {
  opacity: 1;
}
.footer-top__article {
  display: block;
  max-width: 185px;
  width: 100%;
  margin: 0 auto 15px;
}
.footer-top__article:last-child {
  margin-bottom: 0;
}
.footer-top__article h3 {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.footer-top__article span {
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  opacity: 0.4;
}
.footer-top__article p {
  margin-top: 10px;
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
  opacity: 0.6;
}
.footer-top__contact-items {
  max-width: 250px;
  width: 100%;
  margin: 0 auto;
}
.footer-top__contact-item {
  display: flex;
  margin-bottom: 15px;
  text-align: left;
}
.footer-top__contact-item:last-child {
  margin-bottom: 0;
}
.footer-top__contact-icon {
  flex: none;
  width: 24px;
  margin-right: 10px;
  text-align: center;
}
.footer-top__contact-icon img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
.footer-top__contact-content {
  overflow: hidden;
}
.footer-top__contact-content a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.footer-top__contact-content a:hover {
  text-decoration: underline;
}
.footer-top__contact-content p,
.footer-top__contact-content a,
.footer-top__contact-content address {
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  font-style: normal;
}
.footer-bottom {
  padding: 15px 0;
  text-align: center;
  background-color: #32354f;
}
.footer-bottom__copyright {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}
.footer-bottom__copyright:last-child {
  margin-bottom: 0;
}
.footer-bottom__copyright a {
  display: flex;
  align-items: center;
  color: #fff;
  margin-left: 5px;
}
.footer-bottom__copyright a:hover {
  text-decoration: underline;
}
.footer-bottom__copyright a img {
  display: inline-block;
  max-width: 24px;
  margin-left: 5px;
}
@media (min-width: 768px) {
  .footer-top {
    padding: 40px 0;
    text-align: left;
    border-bottom: 2px solid #fff;
    padding-bottom: 15px;
  }
  .footer-top__wrapper {
    display: flex;
  }
  .footer-top__logo {
    flex: none;
    margin-right: 20px;
  }
  .footer-top__content {
    display: flex;
    flex-grow: 1;
  }
  .footer-top__item {
    margin-bottom: 0;
  }
  .footer-bottom__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .footer-bottom__copyright {
    margin-right: 15px;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .footer-top,
  border-bottom: 2px solid white {
    padding-bottom: 15px;
  }
  .footer-top__logo,
  border-bottom: 2px solid white__logo {
    max-width: 130px;
  }
  .footer-top__content,
  border-bottom: 2px solid white__content {
    justify-content: space-between;
    padding-top: 15px;
  }
  .footer-top__item,
  border-bottom: 2px solid white__item {
    margin: 0 10px 0;
  }
  .footer-top__title,
  border-bottom: 2px solid white__title {
    font-size: 14px;
  }
  .footer-top__menu a,
  border-bottom: 2px solid white__menu a {
    font-size: 14px;
  }
  .footer-top__article,
  border-bottom: 2px solid white__article {
    max-width: 140px;
  }
  .footer-top__article h3,
  border-bottom: 2px solid white__article h3 {
    font-size: 14px;
  }
  .footer-top__article span,
  border-bottom: 2px solid white__article span {
    font-size: 12px;
  }
  .footer-top__article p,
  border-bottom: 2px solid white__article p {
    margin-top: 5px;
    font-size: 12px;
    line-height: 18px;
  }
  .footer-top__contact,
  border-bottom: 2px solid white__contact {
    flex: none;
  }
  .footer-top__contact-items,
  border-bottom: 2px solid white__contact-items {
    max-width: 160px;
  }
  .footer-top__contact-content a,
  border-bottom: 2px solid white__contact-content a {
    display: inline-block;
    width: 100%;
  }
  .footer-top__contact-content p,
  border-bottom: 2px solid white__contact-content p,
  .footer-top__contact-content a,
  border-bottom: 2px solid white__contact-content a,
  .footer-top__contact-content address,
  border-bottom: 2px solid white__contact-content address {
    font-size: 12px;
    line-height: 18px;
  }
}
@media (min-width: 992px) {
  .footer-top__logo {
    max-width: 170px;
    margin-right: 50px;
    border-bottom: 2px solid #fff;
    padding-bottom: 15px;
  }
  .footer-top__content {
    justify-content: space-between;
    padding-top: 25px;
  }
  .footer-top__item {
    margin-right: 20px;
  }
  .footer-top__item:last-child {
    margin-right: 0;
  }
  .footer-top__title {
    margin-bottom: 30px;
  }
  .footer-top__menu li {
    margin-bottom: 20px;
  }
  .footer-top__article:hover span,
  .footer-top__article:hover p {
    opacity: 1;
  }
  .footer-top__article span,
  .footer-top__article p {
    transition: 0.3s;
  }
  .footer-top__contact-items {
    max-width: 200px;
  }
  .footer-top__contact-content a {
    display: inline-block;
    width: 100%;
  }
  .footer-bottom {
    padding: 12px 0 10px;
  }
}
@media (min-width: 1200px) {
  .footer-top {
    padding: 60px 0 40px;
    border-bottom: 2px solid #fff;
    padding-bottom: 15px;
  }
  .footer-top__logo {
    margin-right: 70px;
  }
  .footer-top__articles .footer-top__title {
    margin-bottom: 10px;
  }
  .footer-top__article {
    max-width: 180px;
  }
  .footer-top__contact-items {
    max-width: 250px;
  }
  .footer-top__contact-content p,
  .footer-top__contact-content a,
  .footer-top__contact-content address {
    font-size: 16px;
  }
}
.header {
  padding: 10px 0px;
  transition: 0.4s;
}
.header .container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.header.header--static {
  position: absolute;
  left: 0;
  right: 0;
}
.header__content {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 200px;
  padding: 30px 15px 30px;
  background-color: #fff;
  transform: translateX(-100%);
  transition: 0.3s;
}
.header__content .menu {
  flex-grow: 1;
  overflow: auto;
}
.header__content:after {
  content: '';
  position: fixed;
  top: 0;
  left: 100%;
  bottom: 0;
  width: 100vw;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(4,12,22,0.8);
  transition: 0.3s;
}
.header__auth {
  margin-top: 20px;
}
.header__auth .btn__wrapper {
  margin-bottom: 10px;
}
.header__auth .btn__wrapper:last-child {
  margin-bottom: 0;
}
.header__toggle {
  display: none;
}
.header__toggle:checked ~ .header__bars:before,
.header__toggle:checked ~ .header__bars:after {
  background-color: #fff;
  transition: margin 0.3s ease 0s, transform 0.3s ease 0.3s;
}
.header__toggle:checked ~ .header__bars:before {
  margin-bottom: -2px;
  transform: rotate(-45deg);
}
.header__toggle:checked ~ .header__bars:after {
  margin-top: -2px;
  transform: rotate(45deg);
}
.header__toggle:checked ~ .header__bars span {
  background-color: transparent;
}
.header__toggle:checked ~ .header__content {
  transform: translateX(0);
}
.header__toggle:checked ~ .header__content:after {
  opacity: 1;
  visibility: visible;
}
.header__text {
  color: #3c405a;
  font-size: 14px;
  text-align: center;
}
.header__bars {
  position: relative;
  z-index: 1100;
}
.header__bars:before,
.header__bars:after {
  content: '';
  display: block;
  width: 30px;
  height: 2px;
  background-color: #3c405a;
  transition: color 0.3s ease, margin 0.3s ease 0.3s, transform 0.3s ease 0s;
}
.header__bars:before {
  margin-bottom: 5px;
}
.header__bars:after {
  margin-top: 5px;
}
.header__bars span {
  display: block;
  width: 30px;
  height: 2px;
  background-color: #3c405a;
  transition: background 0s ease 0.3s;
}
@media (min-width: 992px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 15px 0;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(60,60,60,0.3);
  }
  .header--relative {
    position: sticky;
    box-shadow: none;
    padding-top: 2%;
  }
  .header.header--static {
    box-shadow: none;
    background-color: transparent;
  }
  .header.header--static .header__content {
    background-color: transparent;
  }
  .header--hide {
    transform: translateY(-100%);
  }
  .header--auto {
    transform: none;
  }
  .header__text {
    margin-right: 20px;
  }
  .header__bars {
    display: none;
  }
  .header__content {
    position: static;
    align-items: center;
    flex-direction: row;
    flex-grow: 1;
    padding: 0;
    transform: translateX(0%);
  }
  .header__content:after {
    content: none;
  }
  .header__content .menu {
    overflow: unset;
    display: flex;
  }
  .header__auth {
    flex: none;
    display: flex;
    align-items: center;
    margin-top: 0;
  }
  .header__auth .btn__wrapper {
    flex: none;
    margin-right: 10px;
    margin-bottom: 0;
  }
  .header__auth .btn__wrapper:last-child {
    margin-right: 0;
  }
  .header__auth .btn {
    width: 110px;
  }
}
@media (min-width: 1200px) {
  .header__auth .btn {
    width: 130px;
  }
}
.logo {
  display: inline-block;
  max-width: 120px;
  margin-bottom: 10px;
}
.logo img {
  display: block;
  width: 100%;
}
@media (min-width: 992px) {
  .logo {
    max-width: 170px;
  }
}
.menu__list > li {
  position: relative;
  margin-bottom: 15px;
}
.menu__list > li:last-child {
  margin-bottom: 0;
}
.menu__list > li > a {
  display: inline-block;
  color: #3c405a;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .menu {
    flex-grow: 1;
    margin: 0 20px;
  }
  .menu__list {
    display: flex;
    align-items: center;
  }
  .menu__list > li {
    margin: 0 10px;
    text-align: center;
  }
  .menu__list > li:hover > a {
    font-weight: 900;
  }
  .menu__list > li:hover .dropdown {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  .menu__list > li.active > a {
    font-weight: 900;
  }
  .menu__list > li > a {
    font-weight: normal;
    transition: 0.3s;
  }
  .menu__list > li > a:before {
    content: attr(title);
    display: block;
    height: 0;
    opacity: 0;
    font-weight: 900;
    overflow: hidden;
    visibility: hidden;
  }
  .menu__list > li .has-submenu {
    position: relative;
    padding-right: 10px;
    cursor: pointer;
  }
  .menu__list > li .has-submenu:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 0;
    height: 0;
    margin-top: -3px;
    border-top: 4px solid #3c405a;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 4px solid transparent;
  }
}
@media (min-width: 1200px) {
  .menu {
    margin: 0 30px;
  }
  .menu__list > li {
    margin: 0 20px;
  }
}
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.page-wrapper .main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
@media (min-width: 992px) {
  .page-wrapper .header ~ .main {
    padding-top: 95px;
  }
  .page-wrapper .header--static ~ .main {
    padding-top: 0;
  }
}
.api-page .population-explorer-bg {
  background-image: url("../images/population-explorer.jpg");
}
.api-page__header-section {
  display: flex;
  align-items: center;
}
.api-page__header-section-left {
  max-width: 501px;
  line-height: 33px;
  letter-spacing: 1px;
  text-align: left;
}
.api-page--header {
  margin-bottom: 18px;
  line-height: 40px;
  color: #fff;
  font-size: 34px;
}
.api-page--content {
  color: #fff;
  font-size: 24px;
}
.api-page__header-form {
  width: 700px;
  min-height: 500px;
  margin-right: -100px;
  margin-left: 39px;
  background-color: #fff;
}
.api-page__header-form-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
}
.api-page__header-form-status {
  margin-bottom: 60px;
  margin-top: 40px;
  font-size: 22px;
}
.api-page__header-form-content {
  margin-bottom: 85px;
  font-size: 28px;
  text-align: center;
}
.api-page__header-form-link {
  padding: 14px;
  border: 1px solid #38b3be;
  color: #38b3be;
  text-transform: none;
  font-size: 20px;
}
.api-page__research-section {
  padding-top: 80px;
  padding-bottom: 80px;
}
.api-page--research-section-header {
  margin-bottom: 14px;
  line-height: 33px;
  letter-spacing: 1px;
  font-size: 34px;
}
.api-page--research-section-content {
  line-height: 33px;
  letter-spacing: 1px;
  font-size: 26px;
}
.api-page--info-section-bg {
  background-image: url("../images/donald-giannatti.jpg");
  background-size: cover;
  background-position: bottom;
}
.api-page__info-section {
  width: 100%;
  height: 1000px;
  padding-top: 100px;
}
.api-page-info-section {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  color: #fff;
}
.api-page-info-section--icon {
  width: 80px;
  height: 75px;
  margin-right: 24px;
}
.api-page-info-section--header {
  margin-bottom: 14px;
  text-transform: uppercase;
  font-size: 34px;
}
.api-page-info-section--content {
  line-height: 33px;
  letter-spacing: 1px;
  font-size: 26px;
}
.api-page-info-section__content-wrap {
  width: 90%;
}
@media screen and (max-width: 1400px) {
  .api-page__header-form {
    margin-right: 0;
  }
  .api-page__info-section {
    height: 900px;
  }
  .api-page-info-section {
    align-items: unset;
  }
}
@media screen and (max-width: 991px) {
  .api-page__header-section {
    flex-direction: column;
  }
  .api-page__header-section-left {
    max-width: 700px;
  }
  .api-page--header {
    text-align: center;
    font-size: 28px;
  }
  .api-page--content {
    text-align: center;
    margin-bottom: 24px;
  }
  .api-page__header-form {
    margin-left: 0;
  }
}
@media screen and (max-width: 768px) {
  .api-page__header-form {
    width: 100%;
  }
  .api-page__header-form-success {
    padding: 60px 15px;
  }
  .api-page__header-form-status {
    margin-bottom: 50px;
    margin-top: 30px;
  }
  .api-page__header-form-content {
    margin-bottom: 75px;
    font-size: 24px;
  }
  .api-page__header-form-link {
    font-size: 16px;
  }
  .api-page--research-section-header {
    font-size: 28px;
  }
  .api-page--research-section-content {
    font-size: 20px;
  }
  .api-page-info-section--icon {
    width: 70px;
    height: 65px;
  }
  .api-page-info-section--header {
    font-size: 28px;
  }
  .api-page-info-section--content {
    font-size: 20px;
  }
}
.section--padding-no {
  padding: 0;
}
.section--padding-top {
  padding-top: 40px;
}
.section--padding-bottom {
  padding-bottom: 40px;
}
.section--padding-both {
  padding-top: 40px;
  padding-bottom: 40px;
}
.section--bg .section__wrapper,
.section--overlay .section__wrapper {
  padding: 40px 0;
  background-size: cover;
  background-position: 50% 50%;
}
.section--overlay .section__content {
  position: relative;
  z-index: 20;
}
.section--overlay .section__wrapper {
  position: relative;
  padding: 40px 0;
}
.section--overlay .section__wrapper:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background-color: rgba(11,31,59,0.8);
}
@media (min-width: 768px) {
  .section--multiple .section__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  .section--50 .banner,
  .section--75 .banner,
  .section--100 .banner,
  .section--50 .fullvideo,
  .section--75 .fullvideo,
  .section--100 .fullvideo,
  .section--50 .section__wrapper,
  .section--75 .section__wrapper,
  .section--100 .section__wrapper {
    min-height: 100%;
  }
  .section--50 {
    height: 50%;
    max-height: 50%;
    box-sizing: content-box;
  }
  .section--75 {
    height: 75%;
    max-height: 75%;
    box-sizing: content-box;
  }
  .section--100 {
    height: 100%;
    max-height: 100%;
    box-sizing: content-box;
  }
}
@media (min-width: 992px) {
  .section--padding-no {
    padding: 0;
  }
  .section--padding-top {
    padding-top: 60px;
  }
  .section--padding-bottom {
    padding-bottom: 60px;
  }
  .section--padding-both {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .section--bg .section__wrapper,
  .section--overlay .section__wrapper {
    padding: 60px 0;
  }
}
@media (min-width: 1200px) {
  .section--padding-no {
    padding: 0;
  }
  .section--padding-top {
    padding-top: 80px;
  }
  .section--padding-bottom {
    padding-bottom: 80px;
  }
  .section--padding-both {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .section--bg .section__wrapper,
  .section--overlay .section__wrapper {
    padding: 80px 0;
  }
}
.sidebar {
  flex: none;
}
.sidebar__menu li {
  position: relative;
  overflow: hidden;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.sidebar__menu a {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 25px;
  color: #3c405a;
  font-size: 18px;
  font-weight: 700;
}
.sidebar__menu a:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 4px;
  height: 0%;
  background-color: #38b3be;
  transition: 0.3s;
}
.sidebar__menu a:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 0%;
  background-color: rgba(56,179,190,0.04);
  transition: 0.3s;
}
.sidebar__menu a:hover {
  color: #38b3be;
}
.sidebar__menu a:hover:after {
  height: 100%;
}
.sidebar__menu a:hover:before {
  height: 100%;
}
.sidebar__menu a.active {
  color: #38b3be;
}
.sidebar__menu a.active:before {
  height: 100%;
}
.sidebar__menu a.active:after {
  height: 100%;
}
.sidebar__menu a span {
  margin-right: 15px;
  font-size: 25px;
}
@media (min-width: 768px) {
  .sidebar {
    max-width: 250px;
    margin-right: 20px;
  }
  .sidebar__menu a {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .sidebar {
    max-width: 280px;
    margin-right: 80px;
  }
  .sidebar__menu a {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .sidebar {
    margin-right: 110px;
  }
}
.advantage-item__img {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 110px;
  margin-bottom: 20px;
  text-align: center;
}
.advantage-item__content {
  max-width: 320px;
  margin: 0 auto;
  text-align: center;
}
.advantage-item__title {
  margin-bottom: 25px;
  color: #3c405a;
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
}
.advantage-item__text {
  color: #858bac;
  font-size: 16px;
  line-height: 30px;
}
.advantage-item .btn {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .advantage-item {
    padding: 0 10px;
  }
  .advantage-item__content {
    max-width: 280px;
    margin: 0 auto;
  }
  .advantage-item .btn {
    margin-top: 25px;
  }
}
@media (min-width: 992px) {
  .advantage-item {
    display: flex;
    flex-direction: column;
    width: auto;
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0;
    padding: 0;
  }
  .advantage-item__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 200px;
  }
  .advantage-item__title {
    font-size: 18px;
    min-height: 50px;
  }
  .advantage-item__text {
    flex-grow: 1;
    font-size: 14px;
    line-height: 22px;
  }
}
@media (min-width: 1200px) {
  .advantage-item__content {
    max-width: 280px;
  }
  .advantage-item__title {
    font-size: 20px;
  }
  .advantage-item__text {
    font-size: 16px;
    line-height: 30px;
  }
}
.auth {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 100px 0;
  background: url("../images/auth-bg.png") 50% 0% no-repeat;
}
.auth--vertical-center {
  justify-content: center;
}
.auth__subtitle {
  margin-bottom: 15px;
  color: #3c405a;
  font-size: 18px;
  text-align: center;
}
.auth__steps {
  max-width: 200px;
  margin: 0 auto 15px;
  text-align: center;
}
.auth__content {
  flex: none;
  max-width: 430px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}
.auth__text {
  margin-bottom: 20px;
  color: #3c405a;
  font-size: 18px;
  text-align: center;
}
.auth__text:last-child {
  margin-bottom: 0px;
}
.auth__text--small {
  font-size: 14px;
}
.auth__link {
  display: inline-block;
  width: 100%;
  margin-top: 15px;
  color: #38b3be;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
}
.auth__link:hover {
  text-decoration: none;
}
.auth .btn {
  max-width: 100%;
  margin-bottom: 10px;
  border: none;
}
.auth .btn:last-child {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .auth {
    padding: 140px 0;
    background-size: 100%;
  }
  .auth__subtitle {
    margin-bottom: 25px;
    font-size: 20px;
  }
}
.banner {
  padding: 45px 0;
  background-size: cover;
  background-position: 50% 50%;
}
.banner--mask {
  position: relative;
}
.banner--mask:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  display: block;
  background-color: rgba(11,31,59,0.6);
}
.banner--mask .banner__content {
  position: relative;
  z-index: 30;
}
.banner__content {
  text-align: center;
}
.banner__content--light {
  color: #fff;
}
.banner__content--dark {
  color: #3c405a;
}
.banner__title {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 32px;
}
.banner__title:last-child {
  margin-bottom: 0;
}
.banner__title--lighter {
  font-weight: 300;
}
.banner__title--normal {
  font-weight: 400;
}
.banner__title--bold {
  font-weight: 900;
}
.banner__subtitle {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 28px;
}
.banner__text {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 28px;
}
.banner__text:last-child {
  margin-bottom: 0;
}
.banner--big {
  min-height: 100vh;
}
@media (min-width: 768px) {
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
  }
  .banner__content {
    max-width: 650px;
    margin: 0 auto;
  }
  .banner__title {
    font-size: 30px;
    line-height: 40px;
  }
  .banner__subtitle {
    font-size: 18px;
  }
  .banner__text {
    font-size: 18px;
    line-height: 30px;
  }
}
@media (min-width: 992px) {
  .banner--small {
    min-height: auto;
  }
  .banner--big {
    min-height: 600px;
  }
  .banner__content {
    max-width: 800px;
  }
  .banner__title {
    font-size: 35px;
    line-height: 45px;
  }
  .banner .btn {
    margin-top: 10px;
  }
}
@media (min-width: 1200px) {
  .banner {
    padding-top: 80px;
  }
  .banner--small .banner__content {
    max-width: 100%;
  }
  .banner--big {
    min-height: 720px;
  }
  .banner__content {
    max-width: 980px;
  }
  .banner__title {
    margin-bottom: 25px;
    font-size: 40px;
    line-height: 55px;
  }
  .banner__subtitle {
    font-size: 20px;
    line-height: 40px;
  }
  .banner__text {
    font-size: 20px;
    line-height: 40px;
  }
  .banner .btn {
    margin-top: 30px;
  }
}
.btn {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.btn .loader {
  border: 3px solid transparent;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
}
.btn--left {
  text-align: center;
}
.btn--center {
  text-align: center;
}
.btn--right {
  text-align: center;
}
.btn--sm {
  max-width: 135px;
  height: 35px;
  font-size: 14px;
  font-weight: normal;
  line-height: 36px;
  border-radius: 5px;
}
.btn--md,
.btn--lg {
  max-width: 200px;
  width: 100%;
  height: 52px;
  font-size: 16px;
  font-weight: 800;
  line-height: 53px;
  border-radius: 8px;
  text-transform: uppercase;
}
.btn--green {
  color: #fff;
  background-color: #60c556;
}
.btn--red {
  color: #fff;
  background-color: #ff6464;
}
.btn--blue {
  color: #fff;
  background-color: #38b3be;
}
.btn--purple {
  color: #fff;
  background-color: #a19aed;
}
.btn--green_with_black {
  color: #000;
  background-color: #60c556;
}
.btn--bordered {
  background-color: transparent;
}
.btn--bordered.btn--sm {
  border: 1px solid transparent;
}
.btn--bordered.btn--md {
  border: 2px solid transparent;
}
.btn--bordered.btn--lg {
  border: 3px solid transparent;
}
.btn--bordered.btn--green {
  color: #60c556;
  border-color: #60c556;
}
.btn--bordered.btn--red {
  color: #ff6464;
  border-color: #ff6464;
}
.btn--bordered.btn--blue {
  color: #38b3be;
  border-color: #38b3be;
}
.btn--bordered.btn--purple {
  color: #a19aed;
  border-color: #a19aed;
}
.btn--full-width {
  max-width: 100%;
}
.btn--borderless {
  border: none;
  margin: 0;
}
.btn--borderless.btn--green {
  background-color: transparent;
  color: #60c556;
}
.btn--borderless.btn--red {
  background-color: transparent;
  color: #ff6464;
}
.btn--borderless.btn--blue {
  background-color: transparent;
  color: #38b3be;
}
.btn--borderless.btn--purple {
  background-color: transparent;
  color: #a19aed;
}
.btn--hidden {
  visibility: hidden;
}
.btn .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}
@media (min-width: 768px) {
  .btn--left {
    text-align: left;
  }
  .btn--center {
    text-align: center;
  }
  .btn--right {
    text-align: right;
  }
  .btn--lg {
    max-width: 380px;
    height: 80px;
    font-size: 20px;
    line-height: 80px;
    border-radius: 13px;
  }
  .btn--full-width {
    max-width: 100%;
  }
}
@-moz-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.card-number {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 16px;
}
.card-number__label {
  font-size: 14px;
  text-transform: uppercase;
}
.card-number__text {
  font-weight: bold;
}
.card-number a {
  max-width: 110px;
  font-size: 12px;
  text-transform: uppercase;
}
.card.card {
  max-width: 360px;
  width: auto;
}
.card__contact {
  margin-top: 25px;
}
.card__period-select.card__period-select ul {
  max-width: none;
  justify-content: space-between;
}
.card__period-select--item {
  font-size: 16px;
}
.card__period-select--text {
  font-style: italic;
  margin-bottom: 5px;
}
.card__description.card__description {
  padding: 0;
}
.card__content.card__content {
  box-shadow: 0 0 0 2000px rgba(9,66,64,0.4);
}
.card__top-title {
  font-weight: bolder;
  font-size: 18px;
}
.cards {
  margin-bottom: 35px;
}
@media (min-width: 768px) {
  .cards__table {
    max-width: 470px;
  }
}
.case-item {
  max-width: 375px;
  margin: 0 auto 0;
}
.case-item:last-child {
  margin-bottom: 0;
}
.case-item__img {
  margin-bottom: 15px;
}
.case-item__img img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
.case-item__content {
  text-align: center;
}
.case-item__title {
  margin-bottom: 10px;
  color: #3c405a;
  font-size: 20px;
}
.case-item__text {
  color: #858bac;
  font-size: 16px;
  line-height: 30px;
}
@media (min-width: 768px) {
  .case-item {
    padding: 0 7px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .case-item__title {
    font-size: 18px;
  }
  .case-item__text {
    font-size: 14px;
    line-height: 24px;
  }
}
@media (min-width: 992px) {
  .case-item__img {
    margin-bottom: 30px;
  }
  .case-item__content {
    padding: 0 5px;
  }
  .case-item__title {
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) {
  .case-item__img {
    margin-bottom: 30px;
  }
  .case-item__content {
    padding: 0 10px;
  }
  .case-item__title {
    margin-bottom: 20px;
  }
}
.checkbox-list {
  text-align: left;
}
.checkbox-list li {
  margin-bottom: 15px;
}
.checkbox-list li:last-child {
  margin-bottom: 0;
}
.checkbox {
  display: inline-flex;
  align-items: center;
}
.checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.checkbox input[type="checkbox"]:checked ~ .checkbox__mark {
  background-color: #38b3be;
}
.checkbox input[type="checkbox"]:checked ~ .checkbox__mark:after {
  opacity: 1;
}
.checkbox__mark {
  flex: none;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #38b3be;
  cursor: pointer;
  transition: 0.3s;
}
.checkbox__mark:after {
  content: '';
  position: absolute;
  display: block;
  width: 10px;
  height: 6px;
  margin-top: 3px;
  margin-left: 3px;
  opacity: 0;
  border: 1px solid #fff;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  transform: rotate(-45deg);
  background-color: transparent;
  transition: 0.3s;
}
.checkbox__text {
  margin-left: 20px;
  color: #3c405a;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
}
.checkbox__text a {
  color: #38b3be;
  text-decoration: underline;
}
.checkbox__text a:hover {
  text-decoration: none;
}
.contact-section .center .field__label {
  width: 100%;
  color: #fff;
  text-align: center;
}
.contact-section .center .field__error {
  text-align: center;
}
.contact-section .center input,
.contact-section .center textarea {
  text-align: center;
}
.contact-section__info {
  margin-bottom: 45px;
  text-align: center;
}
.contact-section__text {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}
.contact-section__form {
  max-width: 450px;
  width: 100%;
  padding: 30px 20px;
  margin: 0 auto;
  border-radius: 30px;
  background-color: rgba(12,29,51,0.7);
}
.contact-section__form .btn {
  max-width: 100%;
}
@media (min-width: 768px) {
  .contact-section .subtitle {
    margin-bottom: 25px;
    line-height: 45px;
  }
  .contact-section__content {
    display: flex;
    align-items: center;
  }
  .contact-section__info {
    max-width: 45%;
    width: 100%;
    margin-right: 5%;
    margin-bottom: 0;
  }
  .contact-section__form {
    max-width: 50%;
  }
  .contact-section__form textarea {
    line-height: 40px;
  }
}
@media (min-width: 992px) {
  .contact-section__info {
    max-width: 430px;
    margin: 0 auto;
  }
  .contact-section__text {
    font-size: 20px;
    line-height: 35px;
  }
  .contact-section__form {
    flex: none;
    max-width: 500px;
    padding: 45px 35px;
    margin-left: auto;
    margin-right: 0;
    border-radius: 45px;
  }
}
@media (min-width: 1200px) {
  .contact-section__text {
    line-height: 45px;
  }
  .contact-section__form {
    padding: 60px 50px;
  }
}
.contacts {
  max-width: 400px;
  margin: 0 auto;
}
.contacts .subtitle {
  margin-bottom: 20px;
  text-align: center;
}
.contacts__map {
  margin-bottom: 25px;
}
.contacts__map img {
  display: block;
  max-width: 100%;
}
.contacts__address {
  margin-bottom: 15px;
  color: #858bac;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
}
.contacts__email {
  display: flex;
  align-items: center;
  color: #858bac;
  font-size: 16px;
}
.contacts__email:hover {
  text-decoration: underline;
}
.contacts__email img {
  margin-right: 10px;
}
.contacts__info {
  margin-bottom: 25px;
}
.contacts__form .btn {
  max-width: 100%;
}
@media (min-width: 768px) {
  .contacts {
    max-width: 100%;
    display: flex;
  }
  .contacts .subtitle {
    text-align: left;
  }
  .contacts__map {
    flex: none;
    max-width: 50%;
    margin-right: 15px;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .contacts {
    justify-content: center;
  }
  .contacts .subtitle {
    margin-bottom: 25px;
  }
  .contacts__map {
    max-width: 580px;
    margin-right: 25px;
  }
  .contacts__content {
    flex: none;
    max-width: 365px;
  }
}
@media (min-width: 1200px) {
  .contacts .subtitle {
    margin-bottom: 30px;
  }
  .contacts__address {
    margin-bottom: 25px;
  }
  .contacts__info {
    margin-bottom: 30px;
  }
  .contacts__map {
    max-width: 665px;
    margin-right: 60px;
  }
  .contacts__content {
    max-width: 400px;
  }
  .contacts__form textarea {
    height: 210px;
  }
}
.editor *:last-child {
  margin-bottom: 0;
}
.editor h2 {
  margin-bottom: 40px;
  color: #3c405a;
  font-size: 22px;
  line-height: 32px;
  font-weight: 900;
}
.editor h3 {
  margin-bottom: 30px;
  color: #3c405a;
  font-size: 20px;
  line-height: 25px;
}
.editor p {
  margin-bottom: 20px;
  color: #858bac;
  font-size: 16px;
  line-height: 30px;
}
.editor a {
  color: #38b3be;
  font-size: 16px;
  line-height: 30px;
  transition: color 0.3s ease;
}
.editor a:hover {
  color: #3c405a;
}
.editor ul,
.editor ol {
  margin-top: 30px;
  margin-bottom: 30px;
}
.editor ul li,
.editor ol li {
  margin-bottom: 25px;
  color: #858bac;
  font-size: 16px;
  line-height: 25px;
}
.editor ul li:last-child,
.editor ol li:last-child {
  margin-bottom: 0;
}
.editor ul li {
  position: relative;
  padding-left: 20px;
}
.editor ul li:before,
.editor ul li:after {
  content: '';
  position: absolute;
  top: 8px;
}
.editor ul li:before {
  content: '';
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background-color: #38b3be;
}
.editor ul li:after {
  content: '';
  left: 7px;
  border: 4px solid transparent;
  border-left-color: #38b3be;
}
.editor ol {
  counter-reset: list;
}
.editor ol li:before {
  counter-increment: list;
  content: counter(list) '.';
  padding-right: 10px;
  color: #38b3be;
  font-weight: 600;
}
@media (min-width: 768px) {
  .editor h2 {
    font-size: 30px;
  }
}
.errors {
  width: 100%;
  margin-bottom: 20px;
  color: #ff6464;
  text-align: center;
}
.errors > * {
  margin-bottom: 10px;
}
.errors > *:last-child {
  margin-bottom: 0;
}
.errors a {
  color: #ff6464;
  font-weight: bold;
  text-decoration: underline;
}
.errors a:hover {
  text-decoration: none;
}
.exception {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  background: url("../images/auth-bg.png") 50% 0% no-repeat;
}
.exception__content {
  max-width: 350px;
  width: 100%;
  text-align: center;
}
.exception__title span {
  color: #213137;
  font-size: 90px;
}
.exception__title img {
  max-width: 55px;
  width: 100%;
}
.exception__subtitle {
  color: #3c405a;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.exception__text {
  margin-top: 10px;
  color: #3c405a;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  letter-spacing: 1px;
}
.exception .btn {
  margin-top: 30px;
}
.faq-item {
  border-bottom: 1px solid #858bac;
  cursor: pointer;
}
.faq-item.opened .faq-item__title:after {
  margin-top: 8px;
  transform: rotate(225deg);
}
.faq-item__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3c405a;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0;
}
.faq-item__title:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  margin-top: -8px;
  border: 1px solid #858bac;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  transform: rotate(45deg);
  transition: 0.3s;
}
.faq-item__content {
  display: none;
  padding-bottom: 20px;
}
.faq-item__text {
  color: #858bac;
  font-size: 14px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .faq-item__title {
    font-size: 18px;
  }
  .faq-item__text {
    font-size: 16px;
    line-height: 30px;
  }
  .faq-item__content {
    padding-right: 50px;
  }
}
@media (min-width: 992px) {
  .faq-item.opened .faq-item__title:after {
    margin-top: 12px;
  }
  .faq-item__title {
    padding: 30px 0;
    font-size: 20px;
  }
  .faq-item__title:after {
    width: 15px;
    height: 15px;
    margin-top: -12px;
    margin-right: 3px;
  }
  .faq-item__content {
    padding-right: 90px;
    padding-bottom: 30px;
  }
}
.feedbacks {
  position: relative;
  padding-bottom: 60px;
}
.feedbacks--no-nav {
  padding-bottom: 0;
}
.feedbacks .slick-arrow {
  position: absolute;
  bottom: 0;
  display: inline-block;
  width: 55px;
  height: 35px;
  margin: 0 5px;
  color: #fff;
  font-size: 25px;
  line-height: 35px;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  background-color: #38b3be;
}
.feedbacks .slick-prev {
  right: 50%;
}
.feedbacks .slick-next {
  left: 50%;
}
.feedback-item__header {
  margin-bottom: 20px;
  text-align: center;
}
.feedback-item__name {
  margin-bottom: 5px;
  color: #5d5d5d;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
}
.feedback-item__company {
  color: #38b3be;
  font-size: 13px;
  font-weight: bold;
}
.feedback-item__content {
  text-align: center;
}
.feedback-item__text {
  color: #5d5d5d;
  font-size: 16px;
  line-height: 30px;
}
.feedback-item__text:before {
  content: '“';
  margin-right: 5px;
}
.feedback-item__text:after {
  content: '”';
  margin-left: 5px;
}
.feedback-item__text:before,
.feedback-item__text:after {
  color: #38b3be;
  font-size: 50px;
  line-height: 0px;
  vertical-align: bottom;
}
@media (min-width: 768px) {
  .feedbacks {
    max-width: 950px;
    padding-bottom: 75px;
    margin: 0 auto;
  }
  .feedbacks--no-nav {
    padding-bottom: 0;
  }
  .feedbacks__prev,
  .feedbacks__next {
    margin: 0 10px;
  }
  .feedback-item__header {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .feedback-item__text {
    font-size: 20px;
    line-height: 36px;
  }
}
.field {
  margin-bottom: 25px;
}
.field.field--center {
  text-align: center;
}
.field.field--center input[type="tel"],
.field.field--center input[type="text"],
.field.field--center input[type="email"],
.field.field--center input[type="password"] {
  text-align: center;
}
.field.field--center textarea {
  text-align: center;
}
.field.field--error input[type="tel"],
.field.field--error input[type="text"],
.field.field--error input[type="email"],
.field.field--error input[type="password"] {
  color: #ff6464;
  border-color: #ff6464;
  background-color: #ffefef;
}
.field.field--error input[type="tel"]::-webkit-input-placeholder,
.field.field--error input[type="text"]::-webkit-input-placeholder,
.field.field--error input[type="email"]::-webkit-input-placeholder,
.field.field--error input[type="password"]::-webkit-input-placeholder {
  color: #ff6464;
}
.field.field--error input[type="tel"]:-moz-placeholder,
.field.field--error input[type="text"]:-moz-placeholder,
.field.field--error input[type="email"]:-moz-placeholder,
.field.field--error input[type="password"]:-moz-placeholder {
  color: #ff6464;
}
.field.field--error input[type="tel"]::-moz-placeholder,
.field.field--error input[type="text"]::-moz-placeholder,
.field.field--error input[type="email"]::-moz-placeholder,
.field.field--error input[type="password"]::-moz-placeholder {
  color: #ff6464;
}
.field.field--error input[type="tel"]:-ms-input-placeholder,
.field.field--error input[type="text"]:-ms-input-placeholder,
.field.field--error input[type="email"]:-ms-input-placeholder,
.field.field--error input[type="password"]:-ms-input-placeholder {
  color: #ff6464;
}
.field.field--error textarea {
  color: #ff6464;
  border-color: #ff6464;
  background-color: #ffefef;
}
.field.field--error textarea::-webkit-input-placeholder {
  color: #ff6464;
}
.field.field--error textarea:-moz-placeholder {
  color: #ff6464;
}
.field.field--error textarea::-moz-placeholder {
  color: #ff6464;
}
.field.field--error textarea:-ms-input-placeholder {
  color: #ff6464;
}
.field.field--error .field__error {
  margin-top: 5px;
}
.field.field--error .select2 .select2-selection {
  border-color: #ff6464;
  background-color: #ffefef;
}
.field.field--error .select2 .select2-selection__rendered {
  color: #ff6464;
}
.field.field--error .select2 .select2-selection__arrow b {
  border-top-color: #ff6464;
}
.field.field--error .checkbox__mark {
  border-color: #ff6464;
}
.field input[type="tel"],
.field input[type="text"],
.field input[type="email"],
.field input[type="password"] {
  width: 100%;
  height: 62px;
  padding: 15px 10px;
  color: #3c405a;
  font-size: 16px;
  font-family: 'Lato';
  outline: none;
  border-radius: 4px;
  border: 1px solid #858bac;
  background-color: #fff;
}
.field input[type="tel"]::-webkit-input-placeholder,
.field input[type="text"]::-webkit-input-placeholder,
.field input[type="email"]::-webkit-input-placeholder,
.field input[type="password"]::-webkit-input-placeholder {
  color: #858bac;
  font-size: 16px;
  line-height: 30px;
  opacity: 1;
  transition: 0.3s;
}
.field input[type="tel"]:-moz-placeholder,
.field input[type="text"]:-moz-placeholder,
.field input[type="email"]:-moz-placeholder,
.field input[type="password"]:-moz-placeholder {
  color: #858bac;
  font-size: 16px;
  line-height: 30px;
  opacity: 1;
  transition: 0.3s;
}
.field input[type="tel"]::-moz-placeholder,
.field input[type="text"]::-moz-placeholder,
.field input[type="email"]::-moz-placeholder,
.field input[type="password"]::-moz-placeholder {
  color: #858bac;
  font-size: 16px;
  line-height: 30px;
  opacity: 1;
  transition: 0.3s;
}
.field input[type="tel"]:-ms-input-placeholder,
.field input[type="text"]:-ms-input-placeholder,
.field input[type="email"]:-ms-input-placeholder,
.field input[type="password"]:-ms-input-placeholder {
  color: #858bac;
  font-size: 16px;
  line-height: 30px;
  opacity: 1;
  transition: 0.3s;
}
.field input[type="tel"]:focus::-webkit-input-placeholder,
.field input[type="text"]:focus::-webkit-input-placeholder,
.field input[type="email"]:focus::-webkit-input-placeholder,
.field input[type="password"]:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: 0s;
}
.field input[type="tel"]:focus:-moz-placeholder,
.field input[type="text"]:focus:-moz-placeholder,
.field input[type="email"]:focus:-moz-placeholder,
.field input[type="password"]:focus:-moz-placeholder {
  opacity: 0;
  transition: 0s;
}
.field input[type="tel"]:focus::-moz-placeholder,
.field input[type="text"]:focus::-moz-placeholder,
.field input[type="email"]:focus::-moz-placeholder,
.field input[type="password"]:focus::-moz-placeholder {
  opacity: 0;
  transition: 0s;
}
.field input[type="tel"]:focus:-ms-input-placeholder,
.field input[type="text"]:focus:-ms-input-placeholder,
.field input[type="email"]:focus:-ms-input-placeholder,
.field input[type="password"]:focus:-ms-input-placeholder {
  opacity: 0;
  transition: 0s;
}
.field input[type="password"] {
  font-size: 30px;
}
.field textarea {
  width: 100%;
  padding: 20px 15px;
  font-size: 16px;
  font-family: 'Lato';
  border-radius: 4px;
  resize: vertical;
  outline: none;
  border: 1px solid #858bac;
}
.field textarea::-webkit-input-placeholder {
  color: #858bac;
  font-size: 16px;
  opacity: 1;
  transition: 0.3s;
}
.field textarea:-moz-placeholder {
  color: #858bac;
  font-size: 16px;
  opacity: 1;
  transition: 0.3s;
}
.field textarea::-moz-placeholder {
  color: #858bac;
  font-size: 16px;
  opacity: 1;
  transition: 0.3s;
}
.field textarea:-ms-input-placeholder {
  color: #858bac;
  font-size: 16px;
  opacity: 1;
  transition: 0.3s;
}
.field textarea:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: 0s;
}
.field textarea:focus:-moz-placeholder {
  opacity: 0;
  transition: 0s;
}
.field textarea:focus::-moz-placeholder {
  opacity: 0;
  transition: 0s;
}
.field textarea:focus:-ms-input-placeholder {
  opacity: 0;
  transition: 0s;
}
.field__label {
  display: inline-block;
  margin-bottom: 15px;
  color: #3c405a;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
}
.field__label--white {
  color: #fff;
}
.field__label--center {
  width: 100%;
  text-align: center;
}
.field__label--left {
  font-weight: normal;
  margin-bottom: 2px;
}
.field__error {
  color: #ff6464;
  font-size: 14px;
}
.field__error--center {
  text-align: center;
}
.field .select2 {
  width: 100%;
}
.field .select2 .select2-selection {
  width: 100%;
  height: 62px;
  padding: 15px 20px;
  color: #3c405a;
  font-size: 16px;
  font-family: 'Lato';
  outline: none;
  border-radius: 4px;
  border: 1px solid #858bac;
  background-color: #fff;
}
.field .select2 .select2-selection__rendered {
  padding: 0;
}
.field .select2 .select2-selection__arrow {
  height: 62px;
  margin-right: 10px;
}
.field.field--error .StripeElement {
  border-color: #ff6464;
  background-color: #ffefef;
}
.field .StripeElement {
  padding: 20px 10px;
  height: 60px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #858bac;
  background-color: #fff;
}
.fullvideo {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  padding: 45px 0;
  background-size: cover;
  background-position: 50% 50%;
}
.fullvideo--mask {
  position: relative;
}
.fullvideo--mask:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  display: block;
  background-color: rgba(11,31,59,0.6);
}
.fullvideo--mask .fullvideo__content {
  position: relative;
  z-index: 30;
}
.fullvideo__content {
  position: relative;
  z-index: 10;
  text-align: center;
}
.fullvideo__content--light {
  color: #fff;
}
.fullvideo__content--dark {
  color: #3c405a;
}
.fullvideo__title {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 32px;
}
.fullvideo__title:last-child {
  margin-bottom: 0;
}
.fullvideo__title--normal {
  font-weight: 400;
}
.fullvideo__title--bold {
  font-weight: 900;
}
.fullvideo__subtitle {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 28px;
}
.fullvideo__text {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 28px;
}
.fullvideo__text:last-child {
  margin-bottom: 0;
}
.fullvideo__play {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 65px;
  margin-top: 10px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #38b3be;
}
.fullvideo__play img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
}
@media (min-width: 768px) {
  .fullvideo {
    min-height: 400px;
    padding: 60px 0;
  }
  .fullvideo__content {
    max-width: 650px;
    margin: 0 auto;
  }
  .fullvideo__title {
    font-size: 30px;
    line-height: 40px;
  }
  .fullvideo__subtitle {
    font-size: 18px;
  }
  .fullvideo__text {
    font-size: 18px;
    line-height: 30px;
  }
}
@media (min-width: 992px) {
  .fullvideo {
    min-height: 600px;
  }
  .fullvideo__content {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .fullvideo {
    min-height: 800px;
  }
  .fullvideo__content {
    max-width: 980px;
  }
  .fullvideo__title {
    margin-bottom: 25px;
  }
  .fullvideo__subtitle {
    font-size: 20px;
    line-height: 40px;
  }
  .fullvideo__text {
    font-size: 20px;
    line-height: 40px;
  }
}
.image-block {
  height: 25vh;
  background-size: cover;
  background-position: 50% 50%;
}
.image-block--50 {
  height: 50vh;
}
.image-block--75 {
  height: 75vh;
}
.image-block--100 {
  height: 100vh;
}
.info-block {
  max-width: 360px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
.info-block:last-child {
  margin-bottom: 0;
}
.info-block .subtitle {
  margin-bottom: 10px;
}
.info-block__item {
  position: relative;
}
.info-block__item.has-subitem {
  padding: 45px 0 25px;
  margin-bottom: 25px;
}
.info-block__item.has-subitem .info-block__image {
  margin-bottom: 0;
}
.info-block__subitem {
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  max-width: 275px;
  width: 100%;
  padding: 15px 40px;
  text-align: center;
  border: 1px solid #38b3be;
  background-color: rgba(56,179,190,0.04);
  transform: translateX(-50%);
}
.info-block__subitem:before {
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}
.info-block__subitem:after {
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}
.info-block__subcontent:before {
  left: 0;
  bottom: 0;
  transform: translate(-50%, 50%);
}
.info-block__subcontent:after {
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);
}
.info-block__subcontent p {
  color: #38b3be;
  font-size: 16px;
  font-weight: 700;
}
.info-block__subcontent img {
  position: absolute;
  top: -25px;
  left: 50%;
  display: block;
  max-width: 110px;
  transform: translateX(-50%);
}
.info-block__subitem:before,
.info-block__subcontent:before,
.info-block__subitem:after,
.info-block__subcontent:after {
  content: '';
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border: 5px solid #38b3be;
  background-color: #fff;
}
.info-block__content {
  text-align: center;
}
.info-block__image {
  position: relative;
  z-index: 10;
  margin-bottom: 25px;
  text-align: center;
}
.info-block__image img {
  display: inline-block;
  max-width: 100%;
}
.info-block__text {
  color: #858bac;
  font-size: 16px;
  line-height: 30px;
  font-family: 'Lato';
}
.info-block__subtext {
  margin-bottom: 10px;
  color: #858bac;
}
.info-block__play {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #38b3be;
  transform: translate(-50%, -50%);
}
.info-block__play img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
}
.info-block .btn {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .info-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    margin-bottom: 75px;
  }
  .info-block:last-child {
    margin-bottom: 0;
  }
  .info-block .subtitle {
    margin-bottom: 25px;
    line-height: 45px;
  }
  .info-block--reverse {
    flex-direction: row-reverse;
  }
  .info-block--reverse .info-block__item.has-subitem {
    padding: 40px 30px 40px 0;
    margin-bottom: 0;
    text-align: right;
  }
  .info-block--reverse .info-block__subitem {
    left: auto;
    right: 0;
    text-align: right;
    padding-left: 20px;
    padding-right: 30px;
  }
  .info-block--reverse .info-block__content {
    padding-left: 0;
    padding-right: 25px;
  }
  .info-block__item {
    width: 50%;
  }
  .info-block__item.has-subitem {
    padding: 40px 0 40px 30px;
    margin-bottom: 0;
    text-align: left;
  }
  .info-block__subitem {
    left: 0;
    text-align: left;
    padding-left: 30px;
    padding-right: 20px;
    transform: translateX(0%);
  }
  .info-block__image {
    display: inline-block;
    margin-bottom: 0;
  }
  .info-block__content {
    padding-left: 25px;
    text-align: left;
  }
  .info-block .btn {
    margin-top: 25px;
  }
}
@media (min-width: 992px) {
  .info-block {
    margin-bottom: 160px;
  }
  .info-block:last-child {
    margin-bottom: 0;
  }
  .info-block--reverse .info-block__item.has-subitem {
    padding: 50px 40px 50px 0;
  }
  .info-block--reverse .info-block__subitem {
    padding-left: 20px;
    padding-right: 40px;
  }
  .info-block--reverse .info-block__content {
    padding-left: 20px;
    padding-right: 50px;
  }
  .info-block__item.has-subitem {
    padding: 50px 0 50px 40px;
  }
  .info-block__subitem {
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 20px;
  }
  .info-block__subtext {
    margin-bottom: 15px;
  }
  .info-block__content {
    padding-left: 50px;
    padding-right: 20px;
  }
  .info-block .btn {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  .info-block--reverse .info-block__image {
    margin-left: 55px;
    margin-right: 0;
  }
  .info-block--reverse .info-block__content {
    padding-left: 0;
    padding-right: 0;
  }
  .info-block__image {
    margin-left: 0;
    margin-right: 55px;
  }
  .info-block__content {
    max-width: 430px;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
.invoices {
  margin-bottom: 35px;
}
@media (min-width: 768px) {
  .invoices__table {
    max-width: 570px;
  }
}
.members {
  margin-bottom: 35px;
}
@media (min-width: 768px) {
  .members__table {
    max-width: 690px;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  display: none;
  align-items: center;
  justify-content: center;
}
.modal .subtitle {
  margin-bottom: 20px;
}
.modal--static {
  overflow: auto;
}
.modal--static .modal__wrapper {
  position: relative;
  display: block;
  padding: 30px 0;
}
.modal__subtitle {
  margin-bottom: 20px;
  color: #858bac;
  font-size: 16px;
  text-align: center;
}
.modal__subtitle a {
  color: #38b3be;
  text-decoration: underline;
}
.modal__subtitle a:hover {
  text-decoration: none;
}
.modal .modal__close {
  position: absolute;
  right: 0;
  bottom: 100%;
  margin-bottom: 10px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
}
.modal__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal__content {
  position: relative;
  width: 100%;
  z-index: 2100;
}
.modal__content--white {
  background-color: #fff;
}
.modal__content--big {
  max-width: 80%;
  padding: 50px 20px;
  margin: 0 10%;
  border-radius: 50px;
}
.modal__content--medium {
  max-width: 332px;
  padding: 30px 30px 20px;
  border-radius: 12px;
}
.modal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2050;
  background-color: rgba(4,12,22,0.9);
}
.modal__overlay--transparent {
  background-color: transparent;
}
.modal__icon {
  margin-bottom: 20px;
  text-align: center;
  cursor: pointer;
}
.modal__icon .icon {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  margin: 0 auto;
}
.modal__icon .icon:before,
.modal__icon .icon:after {
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #858bac;
}
.modal__icon .icon:before {
  transform: rotate(45deg);
}
.modal__icon .icon:after {
  transform: rotate(-45deg);
}
.modal__icon .text {
  color: #858bac;
  font-size: 14px;
  text-transform: uppercase;
}
.modal__form.center .field__label {
  width: 100%;
  text-align: center;
}
.modal__form.center .field__error {
  text-align: center;
}
.modal__form.center input,
.modal__form.center textarea {
  text-align: center;
}
.modal__pay {
  margin-bottom: 15px;
  color: #5d5d5d;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.modal__pay--normal {
  font-weight: normal;
  text-transform: none;
  color: unset;
  margin-bottom: 6px;
}
.modal .video-source {
  width: 100%;
  height: 250px;
}
.modal__cancel {
  color: #ff6464;
  text-align: center;
}
.modal__contact {
  margin-top: 20px;
  text-align: center;
}
@media (min-width: 568px) {
  .modal .video-source {
    height: 350px;
  }
  .modal__content--big {
    max-width: 420px;
  }
}
@media (min-width: 768px) {
  .modal .video-source {
    height: 450px;
  }
  .modal__content--big {
    max-width: 550px;
    margin: 0 auto;
  }
  .modal__form {
    max-width: 395px;
    margin: 0 auto;
  }
  .modal__icon {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .modal .video-source {
    height: 550px;
  }
  .modal__content--big {
    max-width: 755px;
    width: 100%;
    margin: 0 auto;
    padding: 75px 115px 130px;
    border-radius: 110px;
  }
  .modal__icon {
    margin-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  .modal .video-source {
    height: 650px;
  }
}
.multiple {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: -50px;
}
.multiple > * {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .multiple > * {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .multiple {
    margin-bottom: -60px;
  }
  .multiple > * {
    margin-bottom: 60px;
  }
  .multiple--col1 > * {
    width: 100%;
  }
  .multiple--col2 > * {
    width: 50%;
  }
  .multiple--col3 > * {
    width: 33.33%;
  }
  .multiple--col4 > * {
    width: 25%;
  }
}
.partners {
  width: 100%;
}
.partners:last-child .partner-item:last-child {
  margin-bottom: 0;
}
.partner-item {
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .partners {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .partners:last-child .partner-item:last-child {
    margin-bottom: 30px;
  }
  .partner-item {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .partners {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
  }
  .partners:last-child {
    margin-bottom: 0;
  }
  .partners:last-child .partner-item:last-child {
    margin-bottom: 0;
  }
  .partner-item {
    width: 25%;
    margin-bottom: 0;
  }
}
.plan-card {
  position: relative;
  padding-bottom: 80px;
  margin-bottom: 35px;
}
.plan-card:before {
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  bottom: 0;
  display: block;
  border: 1px solid #38b3be;
}
.plan-card--blue .plan-card__subtitle {
  color: #38b3be;
}
.plan-card--blue .plan-card__price {
  color: #38b3be;
}
.plan-card--blue .plan-card__discount {
  background-color: #38b3be;
}
.plan-card--purple:before {
  border-color: #a19aed;
}
.plan-card--purple .plan-card__icon:before,
.plan-card--purple .plan-card__wrapper:before,
.plan-card--purple .plan-card__icon:after,
.plan-card--purple .plan-card__wrapper:after {
  border-color: #a19aed;
}
.plan-card--purple .plan-card__subtitle {
  color: #a19aed;
}
.plan-card--purple .plan-card__price {
  color: #a19aed;
}
.plan-card--purple .plan-card__discount {
  background-color: #a19aed;
}
.plan-card__icon {
  padding: 20px 15px;
}
.plan-card__icon:before,
.plan-card__icon:after {
  content: '';
  position: absolute;
  top: 0;
  z-index: 10;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border: 5px solid #38b3be;
  background-color: #fff;
}
.plan-card__icon:before {
  left: 15px;
  transform: translate(-50%, -50%);
}
.plan-card__icon:after {
  right: 15px;
  transform: translate(50%, -50%);
}
.plan-card__icon img {
  display: block;
  max-width: 125px;
  margin: 0 auto;
}
.plan-card__content {
  position: relative;
  padding: 35px 25px;
  border-radius: 12px;
  text-align: center;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 24px rgba(0,53,133,0.3);
}
.plan-card__wrapper:before,
.plan-card__wrapper:after {
  content: '';
  position: absolute;
  bottom: 0px;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border: 5px solid #38b3be;
  background-color: #fff;
}
.plan-card__wrapper:before {
  left: 15px;
  transform: translate(-50%, 50%);
}
.plan-card__wrapper:after {
  right: 15px;
  transform: translate(50%, 50%);
}
.plan-card__discount {
  position: absolute;
  top: 25px;
  right: -70px;
  width: 200px;
  color: #fff;
  font-size: 13px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  transform: rotate(45deg);
}
.plan-card__title {
  margin-bottom: 20px;
  color: #3c405a;
  font-size: 22px;
  font-weight: 400;
}
.plan-card__subtitle {
  position: absolute;
  left: 25px;
  right: 25px;
  bottom: 20px;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
}
.plan-card__price {
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 800;
}
.plan-card__text {
  color: #3c405a;
  font-size: 16px;
  line-height: 45px;
}
@media (min-width: 768px) {
  .plan-card {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .plan-card__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .plan-card__content {
    flex-grow: 1;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .plan-card__title {
    margin-bottom: 15px;
    font-size: 18px;
  }
  .plan-card__price {
    margin-bottom: 15px;
    font-size: 16px;
  }
  .plan-card__text {
    font-size: 14px;
    line-height: 26px;
  }
}
@media (min-width: 992px) {
  .plan-card {
    padding-bottom: 90px;
  }
  .plan-card__title {
    font-size: 26px;
  }
  .plan-card__subtitle {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .plan-card__title {
    margin-bottom: 20px;
    font-size: 30px;
  }
  .plan-card__subtitle {
    bottom: 20px;
    font-size: 22px;
    line-height: 30px;
  }
  .plan-card__price {
    margin-bottom: 20px;
    font-size: 30px;
  }
}
.plan-detail {
  margin-bottom: 35px;
}
.plan-detail__item {
  margin-bottom: 15px;
}
.plan-detail__item:last-child {
  margin-bottom: 0;
}
.plan-detail__title {
  margin-bottom: 5px;
  color: #3c405a;
  font-size: 20px;
  font-weight: 600;
}
.plan-detail__info {
  color: #3c405a;
  font-size: 18px;
}
.plan-detail__list li {
  margin-bottom: 5px;
  color: #3c405a;
  font-size: 18px;
}
.plan-detail__list li:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .plan-detail__item {
    display: flex;
    margin-bottom: 20px;
  }
  .plan-detail__title {
    max-width: 205px;
    width: 100%;
    margin-right: 20px;
    margin-bottom: 0;
  }
  .plan-detail__info {
    font-size: 20px;
  }
  .plan-detail__list li {
    margin-bottom: 20px;
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .plan-detail__title {
    margin-right: 40px;
  }
}
div.plan-features__mobile-only {
  display: none;
}
div.plan-features__desktop-only {
  display: flex;
}
.plan-features {
  max-width: 350px;
  margin: 0 auto 40px;
}
.plan-features__plan-name {
  font-size: 18px;
  margin-bottom: 20px;
}
.plan-features__plan-price {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 50px;
}
.plan-features__plan-price--sub {
  font-size: 18px;
  vertical-align: text-top;
}
.plan-features__plan-price-description {
  font-size: 12px;
  font-weight: normal;
}
.plan-features__features {
  margin-bottom: 40px;
  padding-left: 50px;
  height: 20px;
}
.plan-features:last-child {
  margin-bottom: 0;
}
.plan-features.annual {
  display: none;
}
.plan-features__title {
  margin-bottom: 100px;
  color: #3c405a;
  font-size: 22px;
  font-weight: 900;
}
.plan-features__subtitle {
  font-size: 18px;
  margin-bottom: 45px;
}
.plan-features__text {
  margin-bottom: 25px;
  color: #858bac;
  font-size: 16px;
  line-height: 30px;
}
.plan-features ul {
  align-self: center;
}
.plan-features ul li {
  position: relative;
  margin-bottom: 25px;
}
.plan-features ul li:last-child {
  margin-bottom: 0;
}
.plan-features ul li p {
  margin-bottom: 25px;
  color: #3c405a;
  font-weight: 700;
  line-height: 20px;
}
.plan-features ul li p:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .plan-features {
    display: flex;
    flex-direction: column;
    max-width: 33.33%;
    padding: 0 5px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
  .plan-features__title {
    margin-bottom: 100px;
  }
  .plan-features__text {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .plan-features__title {
    font-size: 22px;
  }
  .plan-features__text {
    margin-bottom: 45px;
  }
  .plan-features ul li {
    margin-bottom: 40px;
  }
  .plan-features ul li p {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) {
  .plan-features__plan-price {
    margin-bottom: 122px;
  }
  .plan-features ul li {
    margin-bottom: 40px;
  }
}
@media (max-width: 768px) {
  div.plan-features__mobile-only {
    display: flex;
  }
  div.plan-features__desktop-only {
    display: none;
  }
  .plan-features__title {
    margin-bottom: 40px;
    flex-basis: 100%;
  }
  .plan-features__features {
    padding-left: 0;
  }
  .plan-features__plan {
    flex: 1;
  }
  .plan-features ul li {
    margin-bottom: 40px;
    margin-left: 15px;
    white-space: nowrap;
  }
  .plan-features ul:first-child {
    margin-top: 66px;
  }
  .plan-features__button-list {
    margin-bottom: 60px;
    flex-basis: 100%;
  }
  .plan-features__button {
    border: 1px solid #60c556;
    border-radius: 15px;
    background-color: #fff;
    padding: 7px 12px;
    margin-right: 5px;
  }
  .plan-features__button:last-child {
    margin-right: 0;
  }
  .plan-features__button--active {
    color: #fff;
    background-color: #60c556;
  }
  .plan-features__plan-title {
    flex-basis: 100%;
  }
  .plan-features__plan-title.annual {
    display: none;
  }
}
.plan-item-pricing {
  position: relative;
  max-width: 350px;
  padding-top: 145px;
  padding-bottom: 20px;
  margin: 0 15px 50px;
  text-align: center;
  border: 1px solid transparent;
}
.plan-item-pricing:before,
.plan-item-pricing:after {
  content: '';
  position: absolute;
  bottom: 0;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border: 5px solid transparent;
  background-color: #fff;
}
.plan-item-pricing:before {
  left: 0;
  transform: translate(-50%, 50%);
}
.plan-item-pricing:after {
  right: 0;
  transform: translate(50%, 50%);
}
.plan-item-pricing:last-child {
  margin-bottom: 0px;
}
.plan-item-pricing--blue {
  border-color: #38b3be;
  background-color: rgba(56,179,190,0.04);
}
.plan-item-pricing--blue:before,
.plan-item-pricing--blue:after {
  border-color: #38b3be;
}
.plan-item-pricing--blue .plan-item__icon:before,
.plan-item-pricing--blue .plan-item__icon:after {
  border-color: #38b3be;
}
.plan-item-pricing--blue .plan-item__subtitle {
  color: #38b3be;
}
.plan-item-pricing--blue .plan-item__discount {
  background-color: #38b3be;
}
.plan-item-pricing--blue .plan-item__subtext {
  color: #38b3be;
}
.plan-item-pricing--blue .plan-item__active {
  color: #38b3be;
  border-top: 1px solid #38b3be;
}
.plan-item-pricing--purple {
  border-color: #a19aed;
  background-color: rgba(161,154,237,0.04);
}
.plan-item-pricing--purple:before,
.plan-item-pricing--purple:after {
  border-color: #a19aed;
}
.plan-item-pricing--purple .plan-item__icon:before,
.plan-item-pricing--purple .plan-item__icon:after {
  border-color: #a19aed;
}
.plan-item-pricing--purple .plan-item__subtitle {
  color: #a19aed;
}
.plan-item-pricing--purple .plan-item__discount {
  background-color: #a19aed;
}
.plan-item-pricing--purple .plan-item__subtext {
  color: #a19aed;
}
.plan-item-pricing--purple .plan-item__active {
  color: #a19aed;
  border-top: 1px solid #a19aed;
}
.plan-item-pricing__icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 145px;
}
.plan-item-pricing__icon:before,
.plan-item-pricing__icon:after {
  content: '';
  position: absolute;
  top: 0;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border: 5px solid transparent;
  background-color: #fff;
}
.plan-item-pricing__icon:before {
  left: 0;
  transform: translate(-50%, -50%);
}
.plan-item-pricing__icon:after {
  right: 0;
  transform: translate(50%, -50%);
}
.plan-item-pricing__content {
  position: relative;
  overflow: hidden;
  margin: 0 -15px;
  padding: 30px 15px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 24px rgba(0,53,133,0.3);
}
.plan-item-pricing__title {
  margin-bottom: 10px;
  padding: 0 40px;
  color: #3c405a;
  font-size: 22px;
  font-weight: 400;
}
.plan-item-pricing__subtitle {
  margin-bottom: 5px;
  padding: 0 30px;
  font-size: 22px;
  font-weight: 800;
}
.plan-item-pricing__description {
  margin-bottom: 40px;
  color: #3c405a;
  font-size: 16px;
  line-height: 30px;
}
.plan-item-pricing__discount {
  position: absolute;
  top: 90px;
  right: -25px;
  width: 200px;
  padding: 7px 10px 5px;
  color: #fff;
  font-size: 10px;
  transform: rotate(45deg);
  text-transform: uppercase;
  transform-origin: 100% 100%;
}
.plan-item-pricing__subtext {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 800;
}
.plan-item-pricing__active {
  display: inline-block;
  width: 100%;
  height: 52px;
  font-size: 16px;
  line-height: 52px;
  font-weight: bold;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .plan-item-pricing {
    display: flex;
    flex-direction: column;
    max-width: 33.33%;
    width: calc(33.33% - 40px);
    padding-bottom: 85px;
    margin: 0px 20px 0;
  }
  .plan-item-pricing.annual {
    display: none;
  }
  .plan-item-pricing__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .plan-item-pricing__description {
    flex-grow: 1;
  }
  .plan-item-pricing__subtext {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .plan-item-pricing__content {
    padding: 45px;
  }
  .plan-item-pricing__title {
    margin-bottom: 25px;
    padding: 0;
    font-size: 30px;
  }
  .plan-item-pricing__subtitle {
    margin-bottom: 20px;
    padding: 0;
    font-size: 22px;
  }
  .plan-item-pricing__description {
    line-height: 45px;
  }
  .plan-item-pricing__discount {
    top: 105px;
    right: -15px;
    font-size: 13px;
    font-weight: 800;
  }
  .plan-item-pricing__subtext {
    font-size: 22px;
  }
}
@media (min-width: 1200px) {
  .plan-item-pricing__subtitle {
    font-size: 30px;
  }
  .plan-item-pricing__description {
    padding: 0 30px;
    margin-bottom: 45px;
  }
  .plan-item-pricing__discount {
    top: 100px;
    right: -10px;
    padding: 10px 0 7px 0;
  }
}
.plan-item {
  position: relative;
  max-width: 350px;
  margin: 0 15px 50px;
  text-align: center;
  border: 1px solid transparent;
  width: 100%;
}
.plan-item.annual {
  display: none;
}
.plan-item:last-child {
  margin-bottom: 0;
}
.plan-item__content {
  position: relative;
  overflow: hidden;
  margin: 0 -15px;
  padding: 30px 15px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
  border: 1px solid #000;
}
.plan-item__title {
  margin-bottom: 10px;
  padding: 0 40px;
  font-size: 18px;
  font-weight: bold;
  align-self: flex-start;
}
.plan-item__subtitle {
  margin-bottom: 5px;
  padding: 0 30px;
  font-size: 22px;
  font-weight: 800;
  align-self: flex-start;
  margin-right: -20px;
}
.plan-item__subtitle--sub {
  font-size: 18px;
  vertical-align: text-top;
}
.plan-item__payment_description {
  font-size: 16px;
  font-weight: normal;
  padding-left: 10px;
}
.plan-item__filler {
  height: 35px;
}
.plan-item__terms {
  font-size: 11px;
}
.plan-item__description {
  margin-bottom: 40px;
  color: #3c405a;
  font-size: 16px;
  line-height: 25px;
  text-align: start;
}
.plan-item__description--accent {
  color: #ff6464;
}
.plan-item__description-title {
  margin-top: 30px;
  font-size: 20px;
  text-align: start;
  min-height: 95px;
}
.plan-item__discount {
  position: absolute;
  top: 90px;
  right: -25px;
  width: 200px;
  padding: 7px 10px 5px;
  color: #fff;
  font-size: 10px;
  transform: rotate(45deg);
  text-transform: uppercase;
  transform-origin: 100% 100%;
}
.plan-item__subtext {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 800;
}
.plan-item__active {
  display: inline-block;
  width: 100%;
  height: 52px;
  font-size: 16px;
  line-height: 52px;
  font-weight: bold;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .plan-item {
    display: flex;
    flex-direction: column;
    width: calc(33.33% - 50px);
    margin: 0 25px 0;
  }
  .plan-item.annual {
    display: none;
  }
  .plan-item__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .plan-item__description {
    flex-grow: 1;
  }
  .plan-item__subtext {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    margin-top: 0;
  }
  .plan-item__description-title {
    min-height: 95px;
  }
  .plan-item__subtitle {
    padding: 0;
    margin-right: 0;
  }
  .plan-item__title {
    padding: 0;
  }
  .plan-item__payment_description {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .plan-item__content {
    padding: 45px;
  }
  .plan-item__title {
    margin-bottom: 25px;
    padding: 0;
    font-size: 20px;
  }
  .plan-item__subtitle {
    margin-bottom: 20px;
    padding: 0;
    font-size: 22px;
    margin-right: -20px;
  }
  .plan-item__discount {
    top: 105px;
    right: -15px;
    font-size: 13px;
    font-weight: 800;
  }
  .plan-item__subtext {
    font-size: 22px;
  }
}
@media (min-width: 1200px) {
  .plan-item__subtitle {
    font-size: 30px;
    margin-right: -20px;
  }
  .plan-item__description {
    padding: 0 10px;
    margin-bottom: 45px;
  }
  .plan-item__discount {
    top: 100px;
    right: -10px;
    padding: 10px 0 7px 0;
  }
}
.plan-toggle-pricing {
  margin-bottom: 30px;
  text-align: center;
}
.plan-toggle-pricing ul {
  display: inline-flex;
  justify-content: center;
  max-width: 160px;
  width: 100%;
  margin: 0 auto;
  border: 2px solid #38b3be;
  border-radius: 5px;
  background-color: #fff;
}
.plan-toggle-pricing ul li {
  width: 50%;
  border-radius: 5px;
}
.plan-toggle-pricing ul li:first-child a {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.plan-toggle-pricing ul li:last-child a {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.plan-toggle-pricing ul a {
  display: block;
  padding: 6px 5px;
  color: #38b3be;
  font-size: 14px;
  font-family: 'Lato';
  font-weight: 700;
  text-align: center;
  border-radius: 5px;
}
.plan-toggle-pricing ul a.active {
  color: #fff;
  background-color: #38b3be;
}
.plan-toggle-pricing ul a.active:focus {
  outline: none;
}
@media (min-width: 768px) {
  .plan-toggle-pricing {
    margin-bottom: 40px;
  }
}
.plan-toggle ul.plan-toggle--active {
  background-color: #38b3be;
  border-color: #38b3be;
}
.plan-toggle {
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.plan-toggle__text {
  display: block;
  margin-bottom: 15px;
}
.plan-toggle__text-part-1 {
  font-size: 14px;
}
.plan-toggle__text-part-2 {
  color: #60c556;
  text-decoration: underline;
  font-size: 14px;
}
.plan-toggle ul {
  display: inline-flex;
  justify-content: center;
  border: 1px solid #858bac;
  border-radius: 5px;
  background-color: #858bac;
  width: 40px;
  height: 20px;
}
.plan-toggle ul li {
  width: 50%;
  border-radius: 5px;
}
.plan-toggle ul a {
  display: block;
  padding: 6px 5px;
  color: #fff;
  font-size: 14px;
  font-family: 'Lato';
  font-weight: 700;
  text-align: center;
  border-radius: 5px;
  height: 100%;
}
.plan-toggle ul a.active {
  color: #858bac;
  background-color: #fff;
}
.plan-toggle ul a.active:focus {
  outline: none;
}
@media (min-width: 768px) {
  .plan-toggle {
    margin-bottom: 15px;
  }
}
.plan-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 15px;
  margin-bottom: 60px;
}
.plan-items--space {
  justify-content: space-between;
}
.plan-items:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .plan-items {
    max-width: 1200px;
    margin: 0 auto 60px;
  }
}
@media (min-width: 992px) {
  .plan-items {
    margin-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .plan-items {
    margin-bottom: 130px;
  }
}
.pricing-table {
  padding: 0 15px;
  margin-bottom: 60px;
}
.pricing-table:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .pricing-table {
    max-width: 1200px;
    margin: 0 auto 60px;
  }
}
@media (min-width: 992px) {
  .pricing-table {
    margin-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .pricing-table {
    margin-bottom: 130px;
  }
}
.profile {
  padding: 100px 0;
}
.profile__plan + hr {
  margin: 30px 0;
}
.profile__wrapper {
  max-width: 400px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}
.profile__content {
  margin-top: 30px;
}
.profile__form {
  width: 100%;
}
.profile__btns .btn {
  margin-bottom: 15px;
}
.profile__btns .btn:last-child {
  margin-bottom: 0;
}
.profile__btns .btn--md {
  max-width: 100%;
}
@media (min-width: 768px) {
  .profile__wrapper {
    max-width: 1100px;
    display: flex;
  }
  .profile__content {
    flex-grow: 1;
    margin-top: 0;
  }
  .profile__form {
    max-width: 410px;
    width: 100%;
  }
  .profile__btns .btn {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .profile__btns .btn:last-child {
    margin-right: 0;
  }
  .profile__btns .btn--md {
    max-width: 220px;
  }
  .profile__btns .btn--full-width {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .profile {
    padding: 140px 0;
  }
  .profile__btns .btn--md {
    max-width: 250px;
  }
  .profile__btns .btn--full-width {
    max-width: 100%;
  }
}
.radio {
  display: inline-flex;
  align-items: center;
}
.radio input[type="radio"],
.radio input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.radio input[type="radio"]:checked ~ .radio__mark:after,
.radio input[type="checkbox"]:checked ~ .radio__mark:after {
  transform: scale(1);
}
.radio__mark {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #38b3be;
  border-radius: 50%;
  cursor: pointer;
}
.radio__mark:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
  border-radius: 50%;
  background-color: #38b3be;
  transform: scale(0);
  transition: 0.3s;
}
.radio__text {
  margin-left: 20px;
  color: #3c405a;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
}
.social__list {
  display: flex;
  justify-content: center;
}
.social__list li {
  margin-right: 10px;
}
.social__list li:last-child {
  margin-right: 0;
}
.social__list a {
  display: block;
  max-width: 25px;
  transition: 0.3s;
}
.social__list a:hover {
  opacity: 0.8;
}
.social__list a img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
@media (min-width: 568px) {
  .social__list li {
    margin-right: 20px;
  }
}
.solution-item {
  position: relative;
  max-width: 280px;
  width: 100%;
  padding: 40px 0 40px;
  margin: 0 auto;
}
.solution-item--big {
  max-width: 320px;
}
.solution-item:last-child {
  margin-bottom: 0;
}
.solution-item:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  bottom: 0;
  border: 1px solid #38b3be;
  background-color: rgba(56,179,190,0.04);
}
.solution-item__icon {
  margin-bottom: 20px;
  text-align: center;
}
.solution-item__icon:before,
.solution-item__icon:after {
  content: '';
  position: absolute;
  top: 0;
  z-index: 10;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border: 5px solid #38b3be;
  background-color: #fff;
}
.solution-item__icon:before {
  left: 15px;
  transform: translate(-50%, -50%);
}
.solution-item__icon:after {
  right: 15px;
  transform: translate(50%, -50%);
}
.solution-item__content {
  position: relative;
  z-index: 10;
  padding: 25px 17px;
  text-align: center;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 24px rgba(0,53,133,0.3);
}
.solution-item__content:before,
.solution-item__content:after {
  content: '';
  position: absolute;
  bottom: -40px;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border: 5px solid #38b3be;
  background-color: #fff;
}
.solution-item__content:before {
  left: 15px;
  transform: translate(-50%, 50%);
}
.solution-item__content:after {
  right: 15px;
  transform: translate(50%, 50%);
}
.solution-item__title {
  margin-bottom: 10px;
  color: #3c405a;
  font-size: 22px;
  font-weight: 400;
}
.solution-item__title--bold {
  font-weight: bold;
}
.solution-item__text {
  color: #3c405a;
  font-size: 16px;
  line-height: 24px;
}
.solution-item .btn {
  margin-top: 30px;
}
.solution-item .btn--borderless {
  margin-top: 0;
}
@media (min-width: 768px) {
  .solution-item {
    display: flex;
    flex-direction: column;
    margin: 0 30px;
  }
  .solution-item:last-child {
    margin-bottom: 30px;
  }
  .solution-item--big {
    margin: 0 15px 0;
  }
  .solution-item--big:last-child {
    margin-bottom: 0;
  }
  .solution-item__icon {
    margin-bottom: 35px;
  }
  .solution-item__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .solution-item__title {
    margin-bottom: 15px;
    font-size: 30px;
  }
  .solution-item__text {
    flex-grow: 1;
  }
}
@media (min-width: 992px) {
  .solution-item {
    max-width: 225px;
    margin: 0 5px;
  }
  .solution-item--big {
    max-width: 380px;
    margin: 0 25px;
    padding-bottom: 80px;
  }
  .solution-item--big .solution-item__content:before,
  .solution-item--big .solution-item__content:after {
    bottom: -80px;
  }
  .solution-item:last-child {
    margin-bottom: 0;
  }
  .solution-item__title {
    font-size: 22px;
  }
  .solution-item__content {
    padding: 20px 10px;
  }
}
@media (min-width: 1200px) {
  .solution-item {
    max-width: 270px;
  }
  .solution-item--big {
    max-width: 430px;
    padding-top: 35px;
    padding-bottom: 110px;
    margin: 0 50px;
  }
  .solution-item--big .solution-item__icon {
    margin-bottom: 35px;
  }
  .solution-item--big .solution-item__content:before,
  .solution-item--big .solution-item__content:after {
    bottom: -110px;
  }
  .solution-item__title {
    font-size: 28px;
  }
  .solution-item__content {
    padding: 45px 15px;
  }
  .solution-item .btn {
    margin-top: 40px;
  }
  .solution-item .btn--borderless {
    margin-top: 0;
  }
}
.steps {
  display: flex;
  align-items: center;
  justify-content: center;
}
.steps__title {
  margin-right: 15px;
  color: #38b3be;
  font-size: 24px;
  font-weight: 900;
}
.steps__list {
  position: relative;
}
.steps__list li {
  color: #38b3be;
  font-size: 24px;
  font-weight: 900;
}
.steps__list li:first-child {
  padding-right: 20px;
}
.steps__list li:last-child {
  padding-left: 20px;
}
.steps__list li:last-child:after {
  display: none;
}
.steps__list li:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: block;
  height: 4px;
  margin-top: -2px;
  border-radius: 2px;
  transform: rotate(-45deg);
  background-color: #38b3be;
}
@media (min-width: 992px) {
  .steps__title {
    font-size: 30px;
  }
  .steps__list li {
    font-size: 30px;
  }
}
.subtitle {
  margin-bottom: 35px;
  color: #3c405a;
  font-size: 22px;
  line-height: 32px;
  font-weight: 900;
}
.subtitle--center {
  text-align: center;
}
.subtitle--light {
  color: #fff;
}
.subtitle--dark {
  color: #3c405a;
}
.subtitle--white {
  color: #fff;
}
.subtitle--normal {
  font-weight: normal;
}
@media (min-width: 768px) {
  .subtitle {
    margin-bottom: 60px;
  }
  .subtitle--mb-small {
    margin-bottom: 15px;
  }
  .subtitle--mb-big {
    margin-bottom: 40px;
  }
  .subtitle--light {
    color: #fff;
  }
  .subtitle--dark {
    color: #3c405a;
  }
}
@media (min-width: 992px) {
  .subtitle {
    margin-bottom: 60px;
    font-size: 30px;
  }
  .subtitle--big {
    font-size: 40px;
  }
  .subtitle--mb-small {
    margin-bottom: 15px;
  }
  .subtitle--mb-big {
    margin-bottom: 50px;
  }
  .subtitle--light {
    color: #fff;
  }
  .subtitle--dark {
    color: #3c405a;
  }
}
.table {
  width: 100%;
}
.table thead {
  display: none;
}
.table tr {
  display: block;
  margin-bottom: 30px;
}
.table tr:last-child {
  margin-bottom: 0;
}
.table td {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #858bac;
}
.table td:before {
  content: attr(title);
}
.table td:last-child {
  border-bottom: none;
}
.table--simple th {
  color: #858bac;
  font-size: 16px;
  text-align: left;
  font-weight: normal;
}
.table--simple td {
  color: #3c405a;
  font-size: 14px;
}
.table--simple td:before {
  text-transform: none;
}
.table--simple .actions {
  text-transform: uppercase;
}
.table--simple .actions a {
  color: #b0b4d1;
}
.table__link {
  color: #38b3be;
}
.table__empty {
  text-align: center;
}
.table .table__empty {
  justify-content: center;
}
.table .table__empty:before {
  content: none;
}
@media (min-width: 768px) {
  .table thead {
    display: table-header-group;
  }
  .table tr {
    display: table-row;
  }
  .table td {
    display: table-cell;
    width: auto;
    border-bottom: none;
  }
  .table td:before {
    content: none;
  }
  .table--simple td {
    font-size: 20px;
  }
  .table--simple .actions a {
    font-size: 14px;
    transition: 0.3s;
  }
  .table--simple .actions a:hover {
    color: #3c405a;
  }
  .table--simple tr th,
  .table--simple tr td {
    padding: 0 45px 25px 0;
  }
  .table--simple tr:last-child td {
    padding-bottom: 0;
  }
  .table__link {
    font-size: 16px;
  }
}
.text-box {
  text-align: center;
}
.text-box .subtitle {
  margin-bottom: 15px;
}
.text-box__text {
  color: #858bac;
  font-size: 16px;
  line-height: 30px;
  font-family: 'Lato';
}
@media (min-width: 768px) {
  .text-box .subtitle {
    margin-bottom: 25px;
  }
}
