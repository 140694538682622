.banner
  padding: 45px 0
  background-size: cover
  background-position: 50% 50%
  &--mask
    position: relative
    &:before
      content: ''
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      z-index: 20
      display: block
      background-color: $banner-mask
    .banner__content
      position: relative
      z-index: 30
  &__content
    text-align: center
    &--light
      color: $white
    &--dark
      color: $dark-blue
  &__title
    margin-bottom: 20px
    font-size: 24px
    line-height: 32px
    &:last-child
      margin-bottom: 0
    &--lighter
      font-weight: 300
    &--normal
      font-weight: 400
    &--bold
      font-weight: 900
  &__subtitle
    display: inline-block
    width: 100%
    margin-bottom: 10px
    font-size: 16px
    line-height: 28px
  &__text
    margin-bottom: 20px
    font-size: 16px
    line-height: 28px
    &:last-child
      margin-bottom: 0
  &--big
    min-height: 100vh

@media(min-width: $sm)
  .banner
    display: flex
    align-items: center
    justify-content: center
    padding: 60px 0
    &__content
      max-width: 650px
      margin: 0 auto
    &__title
      font-size: 30px
      line-height: 40px
    &__subtitle
      font-size: 18px
    &__text
      font-size: 18px
      line-height: 30px

@media(min-width: $md)
  .banner
    &--small
      min-height: auto
    &--big
      min-height: 600px
    &__content
      max-width: 800px
    &__title
      font-size: 35px
      line-height: 45px
    .btn
      margin-top: 10px


@media(min-width: $lg)
  .banner
    padding-top: 80px
    &--small
      .banner__content
        max-width: 100%
    &--big
      min-height: 720px
    &__content
      max-width: 980px
    &__title
      margin-bottom: 25px
      font-size: 40px
      line-height: 55px
    &__subtitle
      font-size: 20px
      line-height: 40px
    &__text
      font-size: 20px
      line-height: 40px
    .btn
      margin-top: 30px
