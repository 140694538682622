.cms-ready
  .header:not(.header--relative)
    top: 46px
    &__content
      top: 46px
  .select2-dropdown
    margin-top: 46px

div.cms
  .cms-messages
    display: none !important
