.btn
  position: relative
  display: inline-block
  width: 100%
  text-align: center
  cursor: pointer
  .loader
    border: 3px solid transparent
    border-top: 3px solid $white
    border-bottom: 3px solid $white
  &--left
    text-align: center
  &--center
    text-align: center
  &--right
    text-align: center
  &--sm
    max-width: 135px
    height: 35px
    font-size: 14px
    font-weight: normal
    line-height: 36px
    border-radius: 5px
  &--md,
  &--lg
    max-width: 200px
    width: 100%
    height: 52px
    font-size: 16px
    font-weight: 800
    line-height: 53px
    border-radius: 8px
    text-transform: uppercase
  &--green
    color: $white
    background-color: $green
  &--red
    color: $white
    background-color: $red
  &--blue
    color: $white
    background-color: $blue
  &--purple
    color: $white
    background-color: $purple
  &--green_with_black
    color: black
    background-color: $green
  &--bordered
    background-color: transparent
    &.btn--sm
      border: 1px solid transparent
    &.btn--md
      border: 2px solid transparent
    &.btn--lg
      border: 3px solid transparent
    &.btn--green
      color: $green
      border-color: $green
    &.btn--red
      color: $red
      border-color: $red
    &.btn--blue
      color: $blue
      border-color: $blue
    &.btn--purple
      color: $purple
      border-color: $purple
  &--full-width
    max-width: 100%
  &--borderless
    border: none
    margin: 0
    &.btn--green
      background-color: transparent
      color: $green
    &.btn--red
      background-color: transparent
      color: $red
    &.btn--blue
      background-color: transparent
      color: $blue
    &.btn--purple
      background-color: transparent
      color: $purple
  &--hidden
    visibility: hidden
  .loader
    position: absolute
    top: 50%
    left: 50%
    width: 24px
    height: 24px
    margin-top: -12px
    margin-left: -12px
    border-radius: 50%
    animation: spin 2s linear infinite

@media(min-width: $sm)
  .btn
    &--left
      text-align: left
    &--center
      text-align: center
    &--right
      text-align: right
    &--lg
      max-width: 380px
      height: 80px
      font-size: 20px
      line-height: 80px
      border-radius: 13px
    &--full-width
      max-width: 100%

@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
