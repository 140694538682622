.text-box
  text-align: center
  .subtitle
    margin-bottom: 15px
  &__text
    color: $gray
    font-size: 16px
    line-height: 30px
    font-family: $lato

@media(min-width: $sm)
  .text-box
    .subtitle
      margin-bottom: 25px