.subtitle
    margin-bottom: 35px
    color: $dark-blue
    font-size: 22px
    line-height: 32px
    font-weight: 900
    &--center
      text-align: center
    &--light
      color: $white
    &--dark
      color: $dark-blue
    &--white
      color: $white
    &--normal
      font-weight: normal

@media(min-width: $sm)
  .subtitle
    margin-bottom: 60px
    &--mb-small
      margin-bottom: 15px
    &--mb-big
      margin-bottom: 40px
    &--light
      color: $white
    &--dark
      color: $dark-blue

@media(min-width: $md)
  .subtitle
    margin-bottom: 60px
    font-size: 30px
    &--big
      font-size: 40px
    &--mb-small
      margin-bottom: 15px
    &--mb-big
      margin-bottom: 50px
    &--light
      color: $white
    &--dark
      color: $dark-blue
