.plan-toggle ul.plan-toggle--active
  background-color: $blue
  border-color: $blue

.plan-toggle
  margin-bottom: 10px
  text-align: center
  display: flex
  align-items: flex-end
  justify-content: space-between
  &__text
    display: block
    margin-bottom: 15px
  &__text-part-1
    font-size 14px
  &__text-part-2
    color: $green
    text-decoration: underline
    font-size 14px
  ul
    display: inline-flex
    justify-content: center
    border: 1px solid $gray
    border-radius: 5px
    background-color: $gray
    width: 40px
    height: 20px
    li
      width: 50%
      border-radius: 5px
    a
      display: block
      padding: 6px 5px
      color: $white
      font-size: 14px
      font-family: $lato
      font-weight: 700
      text-align: center
      border-radius: 5px
      height: 100%
      &.active
        color: $gray
        background-color: $white
        &:focus
          outline none

@media(min-width: $sm)
  .plan-toggle
    margin-bottom: 15px
