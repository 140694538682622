.contacts
  max-width: 400px
  margin: 0 auto
  .subtitle
    margin-bottom: 20px
    text-align: center
  &__map
    margin-bottom: 25px
    img
      display: block
      max-width: 100%
  &__address
    margin-bottom: 15px
    color: $gray
    font-size: 16px
    line-height: 24px
    font-style: normal
  &__email
    display: flex
    align-items: center
    color: $gray
    font-size: 16px
    &:hover
      text-decoration: underline
    img
      margin-right: 10px
  &__info
    margin-bottom: 25px
  &__form
    .btn
      max-width: 100%

@media(min-width: $sm)
  .contacts
    max-width: 100%
    display: flex
    .subtitle
      text-align: left
    &__map
      flex: none
      max-width: 50%
      margin-right: 15px
      margin-bottom: 0

@media(min-width: $md)
  .contacts
    justify-content: center
    .subtitle
      margin-bottom: 25px
    &__map
      max-width: 580px
      margin-right: 25px
    &__content
      flex: none
      max-width: 365px

@media(min-width: $lg)
  .contacts
    .subtitle
      margin-bottom: 30px
    &__address
      margin-bottom: 25px
    &__info
      margin-bottom: 30px
    &__map
      max-width: 665px
      margin-right: 60px
    &__content
      max-width: 400px
    &__form
      textarea
        height: 210px