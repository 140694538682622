.plan-card
  position: relative
  padding-bottom: 80px
  margin-bottom: 35px
  &:before
    content: ''
    position: absolute
    top: 0
    left: 15px
    right: 15px
    bottom: 0
    display: block
    border: 1px solid $blue
  &--blue
    .plan-card__subtitle
      color: $blue
    .plan-card__price
      color: $blue
    .plan-card__discount
      background-color: $blue
  &--purple
    &:before
      border-color: $purple
    .plan-card__icon,
    .plan-card__wrapper
      &:before,
      &:after
        border-color: $purple
    .plan-card__subtitle
      color: $purple
    .plan-card__price
      color: $purple
    .plan-card__discount
      background-color: $purple
  &__icon
    padding: 20px 15px
    &:before,
    &:after
      content: ''
      position: absolute
      top: 0
      z-index: 10
      display: block
      width: 5px
      height: 5px
      border-radius: 50%
      border: 5px solid $blue
      background-color: $white
    &:before
      left: 15px
      transform: translate(-50%, -50%)
    &:after
      right: 15px
      transform: translate(50%, -50%)
    img
      display: block
      max-width: 125px
      margin: 0 auto
  &__content
    position: relative
    padding: 35px 25px
    border-radius: 12px
    text-align: center
    overflow: hidden
    background-color: $white
    box-shadow: 0 0 24px rgba(0, 53, 133, 0.3)
  &__wrapper
    &:before,
    &:after
      content: ''
      position: absolute
      bottom: 0px
      display: block
      width: 5px
      height: 5px
      border-radius: 50%
      border: 5px solid $blue
      background-color: $white
    &:before
      left: 15px
      transform: translate(-50%, 50%)
    &:after
      right: 15px
      transform: translate(50%, 50%)
  &__discount
    position: absolute
    top: 25px
    right: -70px
    width: 200px
    color: $white
    font-size: 13px
    font-weight: 800
    text-align: center
    text-transform: uppercase
    transform: rotate(45deg)
  &__title
    margin-bottom: 20px
    color: $dark-blue
    font-size: 22px
    font-weight: 400
  &__subtitle
    position: absolute
    left: 25px
    right: 25px
    bottom: 20px
    font-size: 16px
    font-weight: 800
    text-align: center
  &__price
    margin-bottom: 25px
    font-size: 20px
    font-weight: 800
  &__text
    color: $dark-blue
    font-size: 16px
    line-height: 45px

@media(min-width: $sm)
  .plan-card
    display: flex
    flex-direction: column
    flex-grow: 1
    &__wrapper
      display: flex
      flex-direction: column
      flex-grow: 1
    &__content
      flex-grow: 1

@media(min-width: $sm) and (max-width: $md)
  .plan-card
    &__title
      margin-bottom: 15px
      font-size: 18px
    &__price
      margin-bottom: 15px
      font-size: 16px
    &__text
      font-size: 14px
      line-height: 26px

@media(min-width: $md)
  .plan-card
    padding-bottom: 90px
    &__title
      font-size: 26px
    &__subtitle
      font-size: 20px

@media(min-width: $lg)
  .plan-card
    &__title
      margin-bottom: 20px
      font-size: 30px
    &__subtitle
      bottom: 20px
      font-size: 22px
      line-height: 30px
    &__price
      margin-bottom: 20px
      font-size: 30px
    