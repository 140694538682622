.case-item
  max-width: 375px
  margin: 0 auto 0
  &:last-child
    margin-bottom: 0
  &__img
    margin-bottom: 15px
    img
      display: block
      max-width: 100%
      margin: 0 auto
  &__content
    text-align: center
  &__title
    margin-bottom: 10px
    color: $dark-blue
    font-size: 20px
  &__text
    color: $gray
    font-size: 16px
    line-height: 30px

@media(min-width: $sm)
  .case-item
    padding: 0 7px

@media(min-width: $sm) and (max-width: $md)
  .case-item
    &__title
      font-size: 18px
    &__text
      font-size: 14px
      line-height: 24px

@media(min-width: $md)
  .case-item
    &__img
      margin-bottom: 30px
    &__content
      padding: 0 5px
    &__title
      margin-bottom: 20px

@media(min-width: $md)
  .case-item
    &__img
      margin-bottom: 30px
    &__content
      padding: 0 10px
    &__title
      margin-bottom: 20px
