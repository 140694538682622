.advantage-item
  &__img
    display: flex
    align-items: center
    justify-content: center
    min-height: 110px
    margin-bottom: 20px
    text-align: center
  &__content
    max-width: 320px
    margin: 0 auto
    text-align: center
  &__title
    margin-bottom: 25px
    color: $dark-blue
    font-size: 20px
    line-height: 25px    
    font-weight: bold
  &__text
    color: $gray
    font-size: 16px
    line-height: 30px
  .btn
    margin-top: 10px
  
@media(min-width: $sm)
  .advantage-item
    padding: 0 10px
    &__content
      max-width: 280px
      margin: 0 auto
    .btn
      margin-top: 25px

@media(min-width: $md)
  .advantage-item
    display: flex
    flex-direction: column
    width: auto
    flex-grow: 1
    flex-shrink: 1
    margin: 0
    padding: 0
    &__content
      display: flex
      flex-direction: column
      flex-grow: 1
      max-width: 200px
    &__title
      font-size: 18px
      min-height: 50px
    &__text
      flex-grow: 1
      font-size: 14px
      line-height: 22px

@media(min-width: $lg)
  .advantage-item
    &__content
      max-width: 280px
    &__title
      font-size: 20px
    &__text
      font-size: 16px
      line-height: 30px