.partners
  width: 100%
  &:last-child
    .partner-item
      &:last-child
        margin-bottom: 0

.partner-item
  text-align: center
  margin-bottom: 30px

@media(min-width: $sm)
  .partners
    display: flex
    flex-wrap: wrap
    align-items: center
    &:last-child
      .partner-item
        &:last-child
          margin-bottom: 30px

  .partner-item
    width: 50%
    
@media(min-width: $md)
  .partners
    display: flex
    flex-wrap: wrap
    align-items: center
    margin-bottom: 30px
    &:last-child
      margin-bottom: 0
      .partner-item
        &:last-child
          margin-bottom: 0

  .partner-item
    width: 25%
    margin-bottom: 0
    
