.social
  &__list
    display: flex
    justify-content: center
    li
      margin-right: 10px
      &:last-child
        margin-right: 0
    a
      display: block
      max-width: 25px
      transition: .3s
      &:hover
        opacity: .8
      img
        display: block
        max-width: 100%
        margin: 0 auto

@media(min-width: $xs)
  .social
    &__list
      li
        margin-right: 20px