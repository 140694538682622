.sidebar
  flex: none
  &__menu
    li
      position: relative
      overflow: hidden
      border-top-right-radius: 8px
      border-bottom-right-radius: 8px
    a
      display: flex
      align-items: center
      width: 100%
      padding: 10px 25px
      color: $dark-blue
      font-size: 18px
      font-weight: 700
      &:before
        content: ''
        position: absolute
        top: 0
        left: 0
        display: block
        width: 4px
        height: 0%
        background-color: $blue
        transition: .3s
      &:after
        content: ''
        position: absolute
        left: 0
        bottom: 0
        display: block
        width: 100%
        height: 0%
        background-color: rgba(56, 179, 190, 0.04)
        transition: .3s
      &:hover
        color: $blue
        &:after
          height: 100%
        &:before
          height: 100%
      &.active
        color: $blue
        &:before
          height: 100%
        &:after
          height: 100%
      span
        margin-right: 15px
        font-size: 25px

@media(min-width: $sm)
  .sidebar
    max-width: 250px
    margin-right: 20px
    &__menu
      a
        font-size: 16px

@media(min-width: $md)
  .sidebar
    max-width: 280px
    margin-right: 80px
    &__menu
      a
        font-size: 20px

@media(min-width: $lg)
  .sidebar
    margin-right: 110px
    
    
