.footer-top
  padding: 30px 0
  text-align: center
  background-color: $dark-blue
  border-bottom: 2px solid white
  padding-bottom: 15px
  &__logo
    margin-bottom: 30px
    img
      display: block
      max-width: 100%
      margin: 0 auto
  &__item
    margin-bottom: 30px
    &:last-child
      margin-bottom: 0
  &__title
    margin-bottom: 20px
    color: $white
    font-size: 20px
  &__link
    color: $white
  &__menu
    li
      margin-bottom: 15px
      &:last-child
        margin-bottom: 0
    a
      color: $white
      font-size: 16px
      transition: .3s
      &:hover
        opacity: 1
  &__article
    display: block
    max-width: 185px
    width: 100%
    margin: 0 auto 15px
    &:last-child
      margin-bottom: 0
    h3
      color: $white
      font-size: 16px
      line-height: 24px
      font-weight: normal
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
    span
      color: $white
      font-size: 13px
      line-height: 15px
      font-weight: bold
      opacity: .4
    p
      margin-top: 10px
      color: $white
      font-size: 14px
      line-height: 22px
      font-weight: normal
      opacity: .6
  &__contact
    &-items
      max-width: 250px
      width: 100%
      margin: 0 auto
    &-item
      display: flex
      margin-bottom: 15px
      text-align: left
      &:last-child 
        margin-bottom: 0
    &-icon
      flex: none
      width: 24px
      margin-right: 10px
      text-align: center
      img
        display: block
        max-width: 100%
        margin: 0 auto
    &-content
      overflow: hidden
      a
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        &:hover
          text-decoration: underline
      p, a, address
        color: $white
        font-size: 14px
        line-height: 22px
        font-style: normal

.footer-bottom
  padding: 15px 0
  text-align: center
  background-color: $darken-blue
  &__copyright
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: center
    margin-bottom: 30px
    color: $white
    font-size: 14px
    line-height: 20px
 
    &:last-child
      margin-bottom: 0
    a
      display: flex
      align-items: center
      color: $white
      margin-left: 5px
      &:hover
        text-decoration: underline
      img
        display: inline-block
        max-width: 24px
        margin-left: 5px


@media(min-width: $sm)
  .footer-top
    padding: 40px 0
    text-align: left
    border-bottom: 2px solid white
    padding-bottom: 15px
    &__wrapper
      display: flex
    &__logo
      flex: none
      margin-right: 20px
    &__content
      display: flex
      flex-grow: 1
    &__item
      margin-bottom: 0
  
  .footer-bottom
    &__wrapper
      display: flex
      align-items: center
      justify-content: space-between
    &__copyright
      margin-right: 15px
      margin-bottom: 0

@media(min-width: $sm) and (max-width: $md)
  .footer-top
  border-bottom: 2px solid white
    padding-bottom: 15px
    &__logo
      max-width: 130px
    &__content
      justify-content: space-between
      padding-top: 15px
    &__item
      margin: 0 10px 0
    &__title
      font-size: 14px
    &__menu
      a
        font-size: 14px
    &__article
      max-width: 140px
      h3
        font-size: 14px
      span
        font-size: 12px
      p
        margin-top: 5px
        font-size: 12px
        line-height: 18px
    &__contact
      flex: none
      &-items
        max-width: 160px
      &-content
        a
          display: inline-block
          width: 100%
        p, a, address
          font-size: 12px
          line-height: 18px

@media(min-width: $md)
  .footer-top
    &__logo
      max-width: 170px
      margin-right: 50px
      border-bottom: 2px solid white
      padding-bottom: 15px
    &__content
      justify-content: space-between
      padding-top: 25px
    &__item
      margin-right: 20px
      &:last-child
        margin-right: 0
    &__title
      margin-bottom: 30px
    &__menu
      li
        margin-bottom: 20px
    &__article
      &:hover
        span, p
          opacity: 1
      span, p
        transition: .3s
    &__contact
      &-items
        max-width: 200px
      &-content
        a
          display: inline-block
          width: 100%

  .footer-bottom
    padding: 12px 0 10px

@media(min-width: $lg)
  .footer-top
    padding: 60px 0 40px
    border-bottom: 2px solid white
    padding-bottom: 15px
    &__logo
      margin-right: 70px
    &__articles
      .footer-top__title
        margin-bottom: 10px
    &__article
      max-width: 180px
    &__contact
      &-items
        max-width: 250px
      &-content
        p, a, address
          font-size: 16px