.errors
  width: 100%
  margin-bottom: 20px
  color: $red
  text-align: center
  & > *
    margin-bottom: 10px
    &:last-child
      margin-bottom: 0
  a
    color: $red
    font-weight: bold
    text-decoration: underline
    &:hover
      text-decoration: none