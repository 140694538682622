.menu
  &__list
    & > li
      position: relative
      margin-bottom: 15px
      &:last-child
        margin-bottom: 0
      & > a
        display: inline-block
        color: $dark-blue
        font-size: 14px
        font-weight: bold
        text-transform: uppercase

@media(min-width: $md)
  .menu
    flex-grow: 1
    margin: 0 20px
    &__list
      display: flex
      align-items: center
      & > li
        margin: 0 10px
        text-align: center
        &:hover
          & > a
            font-weight: 900
          .dropdown
            opacity: 1
            visibility: visible
            transform: translateY(0)
        &.active
          & > a
            font-weight: 900
        & > a
          font-weight: normal
          transition: .3s
          &:before
            content: attr(title)
            display: block
            height: 0
            opacity: 0
            font-weight: 900
            overflow: hidden
            visibility: hidden
        .has-submenu
          position: relative
          padding-right: 10px
          cursor: pointer
          &:after
            content: ''
            position: absolute
            top: 50%
            right: 0
            display: block
            width: 0
            height: 0
            margin-top: -3px
            border-top: 4px solid $dark-blue
            border-left: 3px solid transparent
            border-right: 3px solid transparent
            border-bottom: 4px solid transparent

@media(min-width: $lg)
  .menu
    margin: 0 30px
    &__list
      & > li
        margin: 0 20px
