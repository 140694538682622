*
  box-sizing: border-box

html, body
  height: 100%
  min-height: 100%
  font-family: $lato

html
  scroll-behavior smooth
  &.overflow
    overflow: hidden

li, ol
  list-style: none

body
  position: relative
  word-wrap: break-word

pre
  white-space: pre-wrap

a
  text-decoration: none

button
  border: none
  outline: none

.disabled
  pointer-events: none

.is-disabled
  text-decoration: none
  pointer-events: none
  opacity: 0.6
