.field
  margin-bottom: 25px
  &.field--center
    text-align: center
    input[type="tel"],
    input[type="text"],
    input[type="email"],
    input[type="password"]
      text-align: center
    textarea
      text-align: center
  &.field--error
    input[type="tel"],
    input[type="text"],
    input[type="email"],
    input[type="password"]
      color: $red
      border-color: $red
      background-color: $red-light
      +placeholder()
        color: $red
    textarea
      color: $red
      border-color: $red
      background-color: $red-light
      +placeholder()
        color: $red
    .field__error
      margin-top: 5px
    .select2
      .select2-selection
        border-color: $red
        background-color: $red-light
        &__rendered
          color: $red
        &__arrow
          b
            border-top-color: $red
    .checkbox
      &__mark
        border-color: $red
  input[type="tel"],
  input[type="text"],
  input[type="email"],
  input[type="password"]
    width: 100%
    height: 62px
    padding: 15px 10px
    color: $dark-blue
    font-size: 16px
    font-family: $lato
    outline: none
    border-radius: 4px
    border: 1px solid $gray
    background-color: $white
    +placeholder()
      color: $gray
      font-size: 16px
      line-height: 30px
      opacity: 1
      transition: .3s
    &:focus
      +placeholder()
        opacity: 0
        transition: 0s
  input[type="password"]
    font-size: 30px
  textarea
    width: 100%
    padding: 20px 15px
    font-size: 16px
    font-family: $lato
    border-radius: 4px
    resize: vertical
    outline: none
    border: 1px solid $gray
    +placeholder()
      color: $gray
      font-size: 16px
      opacity: 1
      transition: .3s
    &:focus
      +placeholder()
        opacity: 0
        transition: 0s
  &__label
    display: inline-block
    margin-bottom: 15px
    color: $dark-blue
    font-size: 14px
    font-weight: bold
    line-height: 16px
    &--white
      color: $white
    &--center
      width: 100%
      text-align: center
    &--left
      font-weight: normal
      margin-bottom: 2px
  &__error
    color: $red
    font-size: 14px
    &--center
      text-align: center
  .select2
    width: 100%
    .select2-selection
      width: 100%
      height: 62px
      padding: 15px 20px
      color: $dark-blue
      font-size: 16px
      font-family: $lato
      outline: none
      border-radius: 4px
      border: 1px solid $gray
      background-color: $white
      &__rendered
        padding: 0
      &__arrow
        height: 62px
        margin-right: 10px

.field
  &.field--error
    .StripeElement
      border-color: $red
      background-color: $red-light
  .StripeElement
    padding: 20px 10px
    height: 60px
    border-radius: 4px
    text-align: center
    border: 1px solid $gray
    background-color: $white
