.table
  width: 100%
  thead
    display: none
  tr
    display: block
    margin-bottom: 30px
    &:last-child
      margin-bottom: 0
  td
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    padding: 10px 0
    border-bottom: 1px solid $gray
    &:before
      content: attr(title)
    &:last-child
      border-bottom: none
  &--simple
    th
      color: $gray
      font-size: 16px
      text-align: left
      font-weight: normal
    td
      color: $dark-blue
      font-size: 14px
      &:before
        text-transform: none
    .actions
      text-transform: uppercase
      a
        color: $light-gray
  &__link
    color: $blue
  &__empty 
    text-align: center
  .table__empty 
    justify-content: center
    &:before
      content: none

@media(min-width: $sm)
  .table
    thead
      display: table-header-group
    tr
      display: table-row
    td
      display: table-cell
      width: auto
      border-bottom: none
      &:before
        content: none
    &--simple
      td
        font-size: 20px
      .actions
        a
          font-size: 14px
          transition: .3s
          &:hover
            color: $dark-blue
      tr
        th,
        td
          padding: 0 45px 25px 0
        &:last-child
          td
            padding-bottom: 0
    &__link
      font-size: 16px