.card
  &^[0]
    max-width 360px
    width auto
  &__contact
    margin-top 25px
  &__period-select
    &^[1]
      ul
        max-width none
        justify-content space-between
    &--item
      font-size 16px
    &--text
      font-style italic
      margin-bottom 5px
  &__description
    &^[1]
      padding 0
  &__content
    &^[1]
      box-shadow 0 0 0 2000px rgba(9, 66, 64, 0.4)
  &__top-title
    font-weight bolder
    font-size 18px
