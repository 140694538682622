.header
  padding: 10px 0px
  transition: .4s
  .container
    display: flex
    align-items: flex-end
    justify-content: space-between
  &.header--static
    position: absolute
    left: 0
    right: 0
  &__content
    position: fixed
    top: 0
    left: 0
    bottom: 0
    z-index: 1000
    display: flex
    flex-direction: column
    justify-content: flex-end
    min-width: 200px
    padding: 30px 15px 30px
    background-color: $white
    transform: translateX(-100%)
    transition: .3s
    .menu
      flex-grow: 1
      overflow: auto
    &:after
      content: ''
      position: fixed
      top: 0
      left: 100%
      bottom: 0
      width: 100vw
      opacity: 0
      visibility: hidden
      background-color: rgba(4, 12, 22, 0.8)
      transition: .3s
  &__auth
    margin-top: 20px
    .btn__wrapper
      margin-bottom: 10px
      &:last-child
        margin-bottom: 0
  &__toggle
    display: none
    &:checked
      & ~ .header__bars
        &:before,
        &:after
          background-color: $white
          transition: margin .3s ease 0s, transform .3s ease .3s
        &:before
          margin-bottom: -2px
          transform: rotate(-45deg)
        &:after
          margin-top: -2px
          transform: rotate(45deg)
        span
          background-color: transparent
      & ~ .header__content
        transform: translateX(0)
        &:after
          opacity: 1
          visibility: visible
  &__text
    color: $dark-blue
    font-size: 14px
    text-align: center
  &__bars
    position: relative
    z-index: 1100
    &:before,
    &:after
      content: ''
      display: block
      width: 30px
      height: 2px
      background-color: $dark-blue
      transition: color .3s ease, margin .3s ease .3s, transform .3s ease 0s
    &:before
      margin-bottom: 5px
    &:after
      margin-top: 5px
    span
      display: block
      width: 30px
      height: 2px
      background-color: $dark-blue
      transition: background 0s ease .3s

@media(min-width: $md)
  .header
    position: fixed
    top: 0
    left: 0
    right: 0
    z-index: 1000
    padding: 15px 0
    background-color: $white
    box-shadow: 0 1px 2px rgba(60, 60, 60, .3)
    &--relative
      position sticky
      box-shadow none
      padding-top 2%
    &.header--static
      box-shadow: none
      background-color: transparent
      .header__content
        background-color: transparent
    &--hide
      transform: translateY(-100%)
    &--auto
      transform none
    &__text
      margin-right: 20px
    &__bars
      display: none
    &__content
      position: static
      align-items: center
      flex-direction: row
      flex-grow: 1
      padding: 0
      transform: translateX(0%)
      &:after
        content: none
      .menu
        overflow: unset
        display: flex
    &__auth
      flex: none
      display: flex
      align-items: center
      margin-top: 0
      .btn__wrapper
        flex: none
        margin-right: 10px
        margin-bottom: 0
        &:last-child
          margin-right: 0
      .btn
        width: 110px

@media(min-width: $lg)
  .header
    &__auth
      .btn
        width: 130px
