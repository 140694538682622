.plan-item-pricing
  position: relative
  max-width: 350px
  padding-top: 145px
  padding-bottom: 20px
  margin: 0 15px 50px
  text-align: center
  border: 1px solid transparent
  &:before,
  &:after
    content: ''
    position: absolute
    bottom: 0
    display: block
    width: 5px
    height: 5px
    border-radius: 50%
    border: 5px solid transparent
    background-color: $white
  &:before
    left: 0
    transform: translate(-50%, 50%)
  &:after
    right: 0
    transform: translate(50%, 50%)
  &:last-child
    margin-bottom: 0px
  &--blue
    border-color: $blue
    background-color: rgba(56, 179, 190, 0.04)
    &:before,
    &:after
      border-color: $blue
    .plan-item__icon
      &:before,
      &:after
        border-color: $blue
    .plan-item__subtitle
      color: $blue
    .plan-item__discount
      background-color: $blue
    .plan-item__subtext
      color: $blue
    .plan-item__active
      color: $blue
      border-top: 1px solid $blue
  &--purple
    border-color: $purple
    background-color: rgba(161, 154, 237, 0.04)
    &:before,
    &:after
        border-color: $purple
    .plan-item__icon
      &:before,
      &:after
        border-color: $purple
    .plan-item__subtitle
      color: $purple
    .plan-item__discount
      background-color: $purple
    .plan-item__subtext
      color: $purple
    .plan-item__active
      color: $purple
      border-top: 1px solid $purple
  &__icon
    position: absolute
    top: 0
    left: 0
    right: 0
    display: flex
    align-items: center
    justify-content: center
    height: 145px
    &:before,
    &:after
      content: ''
      position: absolute
      top: 0
      display: block
      width: 5px
      height: 5px
      border-radius: 50%
      border: 5px solid transparent
      background-color: $white
    &:before
      left: 0
      transform: translate(-50%, -50%)
    &:after
      right: 0
      transform: translate(50%, -50%)
  &__content
    position: relative
    overflow: hidden
    margin: 0 -15px
    padding: 30px 15px
    border-radius: 12px
    background-color: $white
    box-shadow: 0 0 24px rgba(0, 53, 133, 0.3)
  &__title
    margin-bottom: 10px
    padding: 0 40px
    color: $dark-blue
    font-size: 22px
    font-weight: 400
  &__subtitle
    margin-bottom: 5px
    padding: 0 30px
    font-size: 22px
    font-weight: 800
  &__description
    margin-bottom: 40px
    color: $dark-blue
    font-size: 16px
    line-height: 30px
  &__discount
    position: absolute
    top: 90px
    right: -25px
    width: 200px
    padding: 7px 10px 5px
    color: $white
    font-size: 10px
    transform: rotate(45deg)
    text-transform: uppercase
    transform-origin: 100% 100%
  &__subtext
    margin-top: 20px
    font-size: 16px
    font-weight: 800
  &__active
    display: inline-block
    width: 100%
    height: 52px
    font-size: 16px
    line-height: 52px
    font-weight: bold
    text-transform: uppercase

@media(min-width: $sm)
  .plan-item-pricing
    display: flex
    flex-direction: column
    max-width: 33.33%
    width: calc(33.33% - 40px)
    padding-bottom: 85px
    margin: 0px 20px 0
    &.annual
      display: none
    &__content
      display: flex
      flex-direction: column
      flex-grow: 1
    &__description
      flex-grow: 1
    &__subtext
      position: absolute
      left: 0
      right: 0
      bottom: 20px
      margin-top: 0

@media(min-width: $md)
  .plan-item-pricing
    &__content
      padding: 45px
    &__title
      margin-bottom: 25px
      padding: 0
      font-size: 30px
    &__subtitle
      margin-bottom: 20px
      padding: 0
      font-size: 22px
    &__description
      line-height: 45px
    &__discount
      top: 105px
      right: -15px
      font-size: 13px
      font-weight: 800
    &__subtext
      font-size: 22px

@media(min-width: $lg)
  .plan-item-pricing
    &__subtitle
      font-size: 30px
    &__description
      padding: 0 30px
      margin-bottom: 45px
    &__discount
      top: 100px
      right: -10px
      padding: 10px 0 7px 0
