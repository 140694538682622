.plan-toggle-pricing
  margin-bottom: 30px
  text-align: center
  ul
    display: inline-flex
    justify-content: center
    max-width: 160px
    width: 100%
    margin: 0 auto
    border: 2px solid $blue
    border-radius: 5px
    background-color: $white
    li
      width: 50%
      border-radius: 5px
      &:first-child
        a
          border-top-left-radius: 0
          border-bottom-left-radius: 0
      &:last-child
        a
          border-top-right-radius: 0
          border-bottom-right-radius: 0
    a
      display: block
      padding: 6px 5px
      color: $blue
      font-size: 14px
      font-family: $lato
      font-weight: 700
      text-align: center
      border-radius: 5px
      &.active
        color: $white
        background-color: $blue
        &:focus
          outline none

@media(min-width: $sm)
  .plan-toggle-pricing
    margin-bottom: 40px
