@font-face
  font-family: $lato
  src: url('../fonts/Lato-Regular.eot')
  src: local('Lato Regular'), local('Lato-Regular'),
      url('../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Lato-Regular.woff2') format('woff2'),
      url('../fonts/Lato-Regular.woff') format('woff'),
      url('../fonts/Lato-Regular.ttf') format('truetype')
  font-style: normal
  font-weight: normal
  text-rendering: optimizeLegibility
  font-display: swap


@font-face
  font-family: $lato
  src: url('../fonts/Lato-Bold.eot')
  src: local('Lato Bold'), local('Lato-Bold'),
      url('../fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Lato-Bold.woff2') format('woff2'),
      url('../fonts/Lato-Bold.woff') format('woff'),
      url('../fonts/Lato-Bold.ttf') format('truetype')
  font-style: normal
  font-weight: bold
  text-rendering: optimizeLegibility
  font-display: swap

@font-face
  font-family: $lato
  src: url('../fonts/Lato-Heavy.eot')
  src: local('Lato Heavy'), local('Lato-Heavy'),
      url('../fonts/Lato-Heavy.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Lato-Heavy.woff2') format('woff2'),
      url('../fonts/Lato-Heavy.woff') format('woff'),
      url('../fonts/Lato-Heavy.ttf') format('truetype')
  font-style: normal
  font-weight: 800
  text-rendering: optimizeLegibility
  font-display: swap

@font-face
  font-family: $lato
  src: url('../fonts/Lato-Black.eot')
  src: local('Lato Black'), local('Lato-Black'),
      url('../fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Lato-Black.woff2') format('woff2'),
      url('../fonts/Lato-Black.woff') format('woff'),
      url('../fonts/Lato-Black.ttf') format('truetype')
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility
  font-display: swap

@font-face
  font-family: $open-sans
  src: url('../fonts/OpenSans-Regular.eot')
  src: local('OpenSans Regular'), local('OpenSans-Regular'),
    url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Regular.woff') format('woff'),
    url('../fonts/OpenSans-Regular.ttf')  format('truetype'),
    url('../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg')
  font-style: normal
  font-weight: normal
  text-rendering: optimizeLegibility
  font-display: swap

@font-face
  font-family: $open-sans
  src: url('../fonts/OpenSans-Bold.eot')
  src: local('OpenSans Bold'), local('OpenSans-Bold'),
    url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Bold.woff') format('woff'),
    url('../fonts/OpenSans-Bold.ttf')  format('truetype'),
    url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg')
  font-style: normal
  font-weight: bold
  text-rendering: optimizeLegibility
  font-display: swap

@font-face
  font-family: $luc-gran
  src: url('../fonts/LucidaGrande-Bold.eot')
  src: local('LucidaGrande Bold'), local('LucidaGrande-Bold'),
    url('../fonts/LucidaGrande-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/LucidaGrande-Bold.woff') format('woff'),
    url('../fonts/LucidaGrande-Bold.ttf')  format('truetype'),
    url('../fonts/LucidaGrande-Bold.svg#LucidaGrande-Bold') format('svg')
  font-style: normal
  font-weight: bold
  text-rendering: optimizeLegibility
  font-display: swap
