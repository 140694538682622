.radio
  display: inline-flex
  align-items: center
  input[type="radio"],
  input[type="checkbox"]
    position: absolute
    opacity: 0
    visibility: hidden
    &:checked
      & ~ .radio__mark
        &:after
          transform: scale(1)
  &__mark
    position: relative
    display: block
    width: 20px
    height: 20px
    border: 1px solid $blue
    border-radius: 50%
    cursor: pointer
    &:after
      content: ''
      position: absolute
      top: 50%
      left: 50%
      display: block
      width: 10px
      height: 10px
      margin: -5px 0 0 -5px
      border-radius: 50%
      background-color: $blue
      transform: scale(0)
      transition: .3s
  &__text
    margin-left: 20px
    color: $dark-blue
    font-size: 16px
    font-weight: normal
    cursor: pointer