.contact-section
  .center
    .field__label
      width: 100%
      color: $white
      text-align: center
    .field__error
      text-align: center
    input,
    textarea
      text-align: center
  &__info
    margin-bottom: 45px
    text-align: center
  &__text
    color: $white
    font-size: 16px
    line-height: 24px
  &__form
    max-width: 450px
    width: 100%
    padding: 30px 20px
    margin: 0 auto
    border-radius: 30px
    background-color: rgba(12, 29, 51, 0.7)
    .btn
      max-width: 100%

@media(min-width: $sm)
  .contact-section
    .subtitle
      margin-bottom: 25px
      line-height: 45px
    &__content
      display: flex
      align-items: center
    &__info
      max-width: 45%
      width: 100%
      margin-right: 5%
      margin-bottom: 0
    &__form
      max-width: 50%
      textarea
        line-height: 40px

@media(min-width: $md)
  .contact-section
    &__info
      max-width: 430px
      margin: 0 auto
    &__text
      font-size: 20px
      line-height: 35px
    &__form
      flex: none
      max-width: 500px
      padding: 45px 35px
      margin-left: auto
      margin-right: 0
      border-radius: 45px

@media(min-width: $lg)
  .contact-section
    &__text
      line-height: 45px
    &__form
      padding: 60px 50px