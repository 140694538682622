.auth
  display: flex
  flex-direction: column
  flex-grow: 1
  padding: 100px 0
  background: url('../images/auth-bg.png') 50% 0% no-repeat
  &--vertical-center
    justify-content: center
  &__subtitle
    margin-bottom: 15px
    color: $dark-blue
    font-size: 18px
    text-align: center
  &__steps
    max-width: 200px
    margin: 0 auto 15px
    text-align: center
  &__content
    flex: none
    max-width: 430px
    width: 100%
    padding: 0 15px
    margin: 0 auto
  &__text
    margin-bottom: 20px
    color: $dark-blue
    font-size: 18px
    text-align: center
    &:last-child
      margin-bottom: 0px
    &--small
      font-size: 14px
  &__link
    display: inline-block
    width: 100%
    margin-top: 15px
    color: $blue
    font-size: 13px
    font-weight: bold
    text-align: center
    text-decoration: underline
    &:hover
      text-decoration: none
  .btn
    max-width: 100%
    margin-bottom: 10px
    border: none
    &:last-child
      margin-bottom: 0

@media(min-width: $md)
  .auth
    padding: 140px 0
    background-size: 100%
    &__subtitle
      margin-bottom: 25px
      font-size: 20px