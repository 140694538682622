.faq-item
  border-bottom: 1px solid $gray
  cursor: pointer
  &.opened
    .faq-item__title
      &:after
        margin-top: 8px
        transform: rotate(225deg)
  &__title
    display: flex
    align-items: center
    justify-content: space-between
    color: $dark-blue
    font-size: 16px
    font-weight: bold
    padding: 20px 0
    &:after
      content: ''
      display: block
      width: 10px
      height: 10px
      margin-top: -8px
      border: 1px solid $gray
      border-top: 1px solid transparent
      border-left: 1px solid transparent
      transform: rotate(45deg)
      transition: .3s
  &__content
    display: none
    padding-bottom: 20px
  &__text
    color: $gray
    font-size: 14px
    line-height: 24px

@media(min-width: $sm)
  .faq-item
    &__title
      font-size: 18px
    &__text
      font-size: 16px
      line-height: 30px
    &__content
      padding-right: 50px

@media(min-width: $md)
  .faq-item
    &.opened
      .faq-item__title
        &:after
          margin-top: 12px
    &__title
      padding: 30px 0
      font-size: 20px
      &:after
        width: 15px
        height: 15px
        margin-top: -12px
        margin-right: 3px
    &__content
      padding-right: 90px
      padding-bottom: 30px
    