.feedbacks
  position: relative
  padding-bottom: 60px
  &--no-nav
    padding-bottom: 0
  .slick-arrow
    position: absolute
    bottom: 0
    display: inline-block
    width: 55px
    height: 35px
    margin: 0 5px
    color: $white
    font-size: 25px
    line-height: 35px
    cursor: pointer
    text-align: center
    border-radius: 4px
    background-color: $blue
  .slick-prev
    right: 50%
  .slick-next
    left: 50%

.feedback-item
  &__header 
    margin-bottom: 20px
    text-align: center
  &__name
    margin-bottom: 5px
    color: $dark-gray
    font-size: 18px
    font-weight: bold
    line-height: 18px
  &__company
    color: $blue
    font-size: 13px
    font-weight: bold
  &__content
    text-align: center
  &__text
    color: $dark-gray
    font-size: 16px
    line-height: 30px
    &:before
      content: '“'
      margin-right: 5px
    &:after
      content: '”'
      margin-left: 5px
    &:before,
    &:after
      color: $blue
      font-size: 50px
      line-height: 0px
      vertical-align: bottom

@media(min-width: $sm)
  .feedbacks
    max-width: 950px
    padding-bottom: 75px
    margin: 0 auto
    &--no-nav
      padding-bottom: 0
    &__prev,
    &__next
      margin: 0 10px

  .feedback-item
    &__header
      margin-bottom: 30px

@media(min-width: $md)
  .feedback-item
    &__text
      font-size: 20px
      line-height: 36px