.logo
  display: inline-block
  max-width: 120px
  margin-bottom: 10px
  img
    display: block
    width: 100%

@media(min-width: $md)
  .logo
    max-width: 170px
