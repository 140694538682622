.exception
  flex-grow: 1
  display: flex
  align-items: center
  justify-content: center
  padding: 100px 0
  background: url('../images/auth-bg.png') 50% 0% no-repeat
  &__content
    max-width: 350px
    width: 100%
    text-align: center
  &__title
    span
      color: #213137
      font-size: 90px
    img
      max-width: 55px
      width: 100%
  &__subtitle
    color: $dark-blue
    font-size: 18px
    font-weight: bold
    letter-spacing: 2px
    text-transform: uppercase
  &__text
    margin-top: 10px
    color: $dark-blue
    font-size: 16px
    line-height: 22px
    font-weight: bold
    letter-spacing: 1px
  .btn
    margin-top: 30px
  