.solution-item
  position: relative
  max-width: 280px
  width: 100%
  padding: 40px 0 40px
  margin: 0 auto
  &--big
    max-width: 320px
  &:last-child
    margin-bottom: 0
  &:after
    content: ''
    display: block
    position: absolute
    top: 0
    left: 15px
    right: 15px
    bottom: 0
    border: 1px solid $blue
    background-color: rgba(56, 179, 190, 0.04)
  &__icon
    margin-bottom: 20px
    text-align: center
    &:before,
    &:after
      content: ''
      position: absolute
      top: 0
      z-index: 10
      display: block
      width: 5px
      height: 5px
      border-radius: 50%
      border: 5px solid $blue
      background-color: $white
    &:before
      left: 15px
      transform: translate(-50%, -50%)
    &:after
      right: 15px
      transform: translate(50%, -50%)
  &__content
    position: relative
    z-index: 10
    padding: 25px 17px
    text-align: center
    border-radius: 12px
    background-color: $white
    box-shadow: 0 0 24px rgba(0, 53, 133, 0.3)
    &:before,
    &:after
      content: ''
      position: absolute
      bottom: -40px
      display: block
      width: 5px
      height: 5px
      border-radius: 50%
      border: 5px solid $blue
      background-color: $white
    &:before
      left: 15px
      transform: translate(-50%, 50%)
    &:after
      right: 15px
      transform: translate(50%, 50%)
  &__title
    margin-bottom: 10px
    color: $dark-blue
    font-size: 22px
    font-weight: 400
    &--bold
      font-weight: bold
  &__text
    color: $dark-blue
    font-size: 16px
    line-height: 24px
  .btn
    margin-top: 30px
    &--borderless
      margin-top: 0

@media(min-width: $sm)
  .solution-item
    display: flex
    flex-direction: column
    margin: 0 30px
    &:last-child
      margin-bottom: 30px
    &--big
      margin: 0 15px 0
      &:last-child
        margin-bottom: 0
    &__icon
      margin-bottom: 35px
    &__content
      display: flex
      flex-direction: column
      flex-grow: 1
    &__title
      margin-bottom: 15px
      font-size: 30px
    &__text
      flex-grow: 1

@media(min-width: $md)
  .solution-item
    max-width: 225px
    margin: 0 5px
    &--big
      max-width: 380px
      margin: 0 25px
      padding-bottom: 80px
      .solution-item__content
        &:before,
        &:after
          bottom: -80px
    &:last-child
      margin-bottom: 0
    &__title
      font-size: 22px
    &__content
      padding: 20px 10px

@media(min-width: $lg)
  .solution-item
    max-width: 270px
    &--big
      max-width: 430px
      padding-top: 35px
      padding-bottom: 110px
      margin: 0 50px
      .solution-item__icon
        margin-bottom: 35px
      .solution-item__content
        &:before,
        &:after
          bottom: -110px
    &__title
      font-size: 28px
    &__content
      padding: 45px 15px
    .btn
      margin-top: 40px
      &--borderless
        margin-top: 0
