.multiple
  display: flex
  flex-wrap: wrap
  justify-content: center
  margin-bottom: -50px
  & > *
    display: flex
    justify-content: center
    width: 100%
    margin-bottom: 50px


@media(min-width: $sm)
  .multiple
    & > *
      width: 50%

@media(min-width: $md)
  .multiple
    margin-bottom: -60px
    & > *
      margin-bottom: 60px
    &--col1
      & > *
        width: 100%
    &--col2
      & > *
        width: 50%
    &--col3
      & > *
        width: 33.33%
    &--col4
      & > *
        width: 25%