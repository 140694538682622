.page-wrapper
  display: flex
  flex-direction: column
  min-height: 100%
  .main
    display: flex
    flex-direction: column
    flex-grow: 1

@media(min-width: $md)
  .page-wrapper
    .header
      & ~ .main
        padding-top: 95px
    .header--static
      & ~ .main
        padding-top: 0