.api-page
  .population-explorer-bg
    background-image url('../images/population-explorer.jpg')
  &__header-section
    display flex
    align-items center
  &__header-section-left
    max-width: 501px;
    line-height 33px
    letter-spacing: 1px;
    text-align left
  &--header
    margin-bottom 18px
    line-height: 40px;
    color $white
    font-size 34px
  &--content
    color $white
    font-size 24px
  &__header-form
    width 700px
    min-height 500px
    margin-right: -100px;
    margin-left: 39px;
    background-color #fff
  &__header-form-success
    display: flex
    flex-direction: column
    align-items: center
    padding: 60px
  &__header-form-status
    margin-bottom: 60px
    margin-top: 40px
    font-size: 22px
  &__header-form-content
    margin-bottom: 85px
    font-size: 28px
    text-align: center
  &__header-form-link
    padding 14px
    border 1px solid $blue
    color $blue
    text-transform none
    font-size: 20px
  &__research-section
      padding-top 80px
      padding-bottom 80px
  &--research-section-header
    margin-bottom 14px
    line-height 33px
    letter-spacing 1px
    font-size 34px
  &--research-section-content
    line-height 33px
    letter-spacing 1px
    font-size 26px
  &--info-section-bg
    background-image url('../images/donald-giannatti.jpg')
    background-size cover
    background-position bottom
  &__info-section
    width 100%
    height 1000px
    padding-top 100px

.api-page-info-section
  display flex
  align-items center
  margin-bottom 26px
  color $white
  &--icon
    width: 80px;
    height: 75px;
    margin-right 24px
  &--header
    margin-bottom 14px
    text-transform uppercase
    font-size 34px
  &--content
    line-height 33px
    letter-spacing 1px
    font-size 26px
  &__content-wrap
    width 90%

@media screen and (max-width 1400px)
  .api-page
    &__header-form
      margin-right 0
    &__info-section
      height 900px
  .api-page-info-section
    align-items unset


@media screen and (max-width 991px)
  .api-page
    &__header-section
      flex-direction column
    &__header-section-left
      max-width 700px
    &--header
      text-align center
      font-size 28px
    &--content
      text-align center
      margin-bottom 24px
    &__header-form
      margin-left 0

@media screen and (max-width 768px)
  .api-page
    &__header-form
      width 100%
    &__header-form-success
      padding 60px 15px
    &__header-form-status
      margin-bottom: 50px
      margin-top: 30px
    &__header-form-content
      margin-bottom: 75px
      font-size: 24px
    &__header-form-link
      font-size: 16px
    &--research-section-header
      font-size 28px
    &--research-section-content
      font-size 20px
  .api-page-info-section
    &--icon
      width 70px
      height 65px
    &--header
      font-size 28px
    &--content
      font-size 20px