.editor
  *
    &:last-child
      margin-bottom: 0
  h2
    margin-bottom: 40px
    color: $dark-blue
    font-size: 22px
    line-height: 32px
    font-weight: 900
  h3
    margin-bottom: 30px
    color: $dark-blue
    font-size: 20px
    line-height: 25px
  p
    margin-bottom: 20px
    color: $gray
    font-size: 16px
    line-height: 30px
  a
    color: $blue
    font-size: 16px
    line-height: 30px
    transition: color .3s ease
    &:hover
      color: $dark-blue
  ul,
  ol
    margin-top: 30px
    margin-bottom: 30px
    li
      margin-bottom: 25px
      color: $gray
      font-size: 16px
      line-height: 25px 
      &:last-child
        margin-bottom: 0
  ul
    li
      position: relative
      padding-left: 20px
      &:before,
      &:after
        content: ''
        position: absolute
        top: 8px
      &:before
        content: ''
        left: 0
        width: 8px
        height: 8px
        border-radius: 2px
        background-color: $blue
      &:after
        content: ''
        left: 7px
        border: 4px solid transparent
        border-left-color: $blue
  ol
    counter-reset: list
    li
      &:before
        counter-increment: list
        content: counter(list) '.'
        padding-right: 10px
        color: $blue
        font-weight: 600

  
@media(min-width: $sm)
  .editor
    h2
      font-size: 30px
