.plan-items
  display: flex
  flex-wrap: wrap
  justify-content: center
  padding: 0 15px
  margin-bottom: 60px
  &--space
    justify-content: space-between
  &:last-child
    margin-bottom: 0

@media(min-width: $sm)
  .plan-items
    max-width: 1200px
    margin: 0 auto 60px

@media(min-width: $md)
  .plan-items
    margin-bottom: 100px

@media(min-width: $lg)
  .plan-items
    margin-bottom: 130px
