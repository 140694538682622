.pricing-table
  padding: 0 15px
  margin-bottom: 60px

  &:last-child
    margin-bottom: 0

@media(min-width: $sm)
  .pricing-table
    max-width: 1200px
    margin: 0 auto 60px

@media(min-width: $md)
  .pricing-table
    margin-bottom: 100px

@media(min-width: $lg)
  .pricing-table
    margin-bottom: 130px