.steps
  display: flex
  align-items: center
  justify-content: center
  &__title
    margin-right: 15px
    color: $blue
    font-size: 24px
    font-weight: 900
  &__list
    position: relative
    li
      color: $blue
      font-size: 24px
      font-weight: 900
      &:first-child
        padding-right: 20px
      &:last-child
        padding-left: 20px
        &:after
          display: none
      &:after
        content: ''
        position: absolute
        top: 50%
        left: 0
        right: 0
        display: block
        height: 4px
        margin-top: -2px
        border-radius: 2px
        transform: rotate(-45deg)
        background-color: $blue

@media(min-width: $md)
  .steps
    &__title
      font-size: 30px
    &__list
      li
        font-size: 30px