.card-number
  display: flex
  align-items: center
  justify-content: space-between
  margin-top: 30px
  margin-bottom: 30px
  font-size: 16px
  &__label
    font-size: 14px
    text-transform: uppercase
  &__text
    font-weight: bold
  a
    max-width: 110px
    font-size: 12px
    text-transform: uppercase