@font-face
  font-family: 'icomoon'
  src:  url('../icon-fonts/icomoon.eot?5etaug')
  src:  url('../icon-fonts/icomoon.eot?5etaug#iefix') format('embedded-opentype'),
    url('../icon-fonts/icomoon.ttf?5etaug') format('truetype'),
    url('../icon-fonts/icomoon.woff?5etaug') format('woff'),
    url('../icon-fonts/icomoon.svg?5etaug#icomoon') format('svg')
  font-weight: normal
  font-style: normal


[class^="icon-"], [class*=" icon-"]
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important
  speak: none
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.icon-credit-cards:before
  content: "\e902"

.icon-plan-info:before
  content: "\e905"

.icon-check:before
  content: "\e903"

.icon-team:before
  content: "\e900"

.icon-document:before
  content: "\e901"

.icon-card:before
  content: "\e904"

.icon-pencil:before
  content: "\e906"
