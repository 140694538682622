.section
  &--padding
    &-no
      padding: 0
    &-top
      padding-top: 40px
    &-bottom
      padding-bottom: 40px
    &-both
      padding-top: 40px
      padding-bottom: 40px
  &--bg,
  &--overlay
    .section__wrapper
      padding: 40px 0
      background-size: cover
      background-position: 50% 50%
  &--overlay
    .section__content
      position: relative
      z-index: 20
    .section__wrapper
      position: relative
      padding: 40px 0
      &:after
        content: ''
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        display: block
        background-color: $contact-mask

@media(min-width: $sm)
  .section
    &--multiple
      .section__content
        display: flex
        flex-wrap: wrap
        justify-content: center
        width: 100%
    &--50,
    &--75,
    &--100
      .banner,
      .fullvideo,
      .section__wrapper
        min-height: 100%
    &--50
      height: 50%
      max-height: 50%
      box-sizing: content-box
    &--75
      height: 75%
      max-height: 75%
      box-sizing: content-box
    &--100
      height: 100%
      max-height: 100%
      box-sizing: content-box

@media(min-width: $md)
  .section
    &--padding
      &-no
        padding: 0
      &-top
        padding-top: 60px
      &-bottom
        padding-bottom: 60px
      &-both
        padding-top: 60px
        padding-bottom: 60px
    &--bg,
    &--overlay
      .section__wrapper
        padding: 60px 0

@media(min-width: $lg)
  .section
    &--padding
      &-no
        padding: 0
      &-top
        padding-top: 80px
      &-bottom
        padding-bottom: 80px
      &-both
        padding-top: 80px
        padding-bottom: 80px
    &--bg,
    &--overlay
      .section__wrapper
        padding: 80px 0