.blog-article
  &__image
    background-position 100% 100%
    background-size cover
  &__image-content
    position: relative
    display: flex
    align-items: center
    min-height: 540px
    padding: 90px 0
    margin-bottom: 40px
  &__title
    max-width: 910px
    margin-bottom: 40px
    line-height: 1.2
    color #fff
    font-size: 46px
    font-weight: 600
  .fixed-part-parent
    padding-bottom 20px
  &__content
    p
      font-size 18px
      line-height 1.7
      font-weight 300
    img
      margin 20px auto
    ol, ul
      list-style unset
      margin-left 10px
    h1
      font-size 46px
      font-weight: 600
    h2
      font-size 38px
      font-weight: 600
    h3
      font-size 32px
      font-weight: 600
    h4
      font-size 24px
      font-weight: 600
    h5
      font-size 18px
      font-weight: 600
    h6
      font-size 16px
      font-weight: 600


  @media screen and (max-width 950px)
    &__title
      margin-bottom: 25px;
      font-size: 36px;
      line-height: 46px;

.blog-section
  .wrap-search
    width: 100%
    transition: padding .4s
    padding: 50px 15px 100px
    &__title
      max-width 900px
      margin-left auto
      margin-right auto
      margin-bottom 30px
      font-size: 36px
      font-weight: 500
      line-height: 46px
      text-align: center
    &__search
      opacity: 1
      transform: translateY(0)
      transition: transform .4s,opacity .4s
      transition-timing-function: cubic-bezier(.5,0,.15,1)
      transform-origin: 50% 50%
      transition-delay: .4s
    &__form
      display: flex
      align-items: stretch
      justify-content: space-between
      width: 100%
      max-width: 800px
      height: 55px
      margin: 0 auto
    &--input
      outline: 0
      width: 100%
      font-size: 18px
      padding: 5px 10px 10px
      color: #090909
      font-weight: 500
      height: 55px
      line-height: 26px
      background-color #fff
      border none
      border-bottom 2px solid $blue
    &__btn
      width 55px
      margin-left 24px
      background-color $blue
      border none
      text-align center
      outline 0
      display flex
      justify-content center
      line-height 55px
  .blog-component
    &__header
      margin-bottom 24px
    &__items
      display flex
      margin-bottom 14px
      margin-left -10px
      margin-right -10px
    &__title
      margin-top: 0
      margin-bottom: 40px
      color: #090909
      font-size: 24px
      font-weight: 500
    .label-component
      display: inline-block
      padding: 10px 25px 9px
      margin-bottom: 20px
      border: 1px solid
      border-radius: 20px
      color: #090909
      text-decoration: none
    .label-orange
      border-color $green

  @media screen and (max-width 950px)
    .blog-component-main
      .blog-item:nth-child(3)
        display none

    .blog-component
      .blog-item
        max-width 47.6%
        width 47.6%
  @media screen and (max-width 640px)
    .blog-component-main
      .blog-item
        width 100%
        max-width 450px
        &:nth-child(3)
          display unset
    .blog-component
        &__items
          flex-wrap wrap
          justify-content center
          margin-left 0
          margin-right 0

  .blog-item
      position relative
      max-width 356px
      width 100%
      height 300px
      margin 10px

      .content
        width 100%
        height 100%
      &__img
        width 60%
        height 100%
        margin-left 40%
      &__content
        position absolute
        left 0
        top 0
        width 100%
        height 100%
        z-index 1
      &__label
        display flex
        flex-direction column
        justify-content flex-end
        width 100%
        height 100%
        padding 24px
        letter-spacing: 1px
      &--label-header
        text-transform uppercase
        padding-top 45%
        padding-bottom 10px
        color $blue
        display: inline-block
        font-size: 12px
        font-weight: 500
        text-decoration: none
      &--label-content
        line-height 22px
        color #fff
        font-size 16px
      &--overlay
        position absolute
        left 0
        top 0
        width 40%
        height 100%
        z-index 0
        background-color rgba(0, 0, 0, 1)
      @media screen and (max-width 1240px)
        height auto
        max-width 356px
      @media screen and (max-width 640px)
        margin-left 0
        margin-right 0

  &__footer
    margin-top 40px

.wrap-section__bg
  background-color #f3f3f3

.blog-component
  padding 80px 0

.blog-category
   position: relative
   padding 60px 0 95px
   &--header
      margin-left auto
      margin-right auto
      max-width 900px
      margin-bottom 90px
      text-align center
      letter-spacing 0
      font-size: 36px
      font-weight 500
      line-height: 46px
   &__content
     display flex
     flex-wrap wrap
     justify-content space-between
   .blog-component
   &__header
      margin-top 50px
      margin-bottom 30px
   &__categories
      width 65%
   &__categories-list
       width 33%
       margin-top 33px
       ul
         list-style none
      .blog-sidebar
        position sticky
        top 10px
        &__title
          display block
          line-height 33px
          margin-bottom 20px
          font-weight 500
          font-size 24px
        &__nav
          margin-bottom: 20px
        &__list-link
          display: block
          width 100%
          height 100%
          padding: 20px 30px
        &--list-link-header
          color: #090909
          display: inline-block
          margin-bottom: 15px
        &--list-link-content
          color: #090909
          display: block
        &__list-item
          border-bottom: 1px solid #f3f3f3
          position: relative
          background-color #fff
          font-size: 12px
          line-height: 16px
          text-transform: uppercase
          color: #090909
        &__list-item:hover
          background-color  #090909
          .blog-sidebar--list-link-header, .blog-sidebar--list-link-content
              color #fff
   .blog-component__items
      flex-wrap wrap
   .active
     background-color #090909
     color: #fff
     .blog-sidebar--list-link-header, .blog-sidebar--list-link-content
       color #fff

   @media screen and (max-width 1240px)
     .blog-item
        width 47%
        max-width unset
        height auto
   @media screen and (max-width 1124px)
     .blog-item
        width 46%
        height auto
   @media screen and (max-width 991px)
     &__header
       margin-bottom 24px
     &__content
      flex-direction column
     &__categories
       width 100%
       order 2
       margin-top 30px
     &__categories-list
       width 100%
       order 1
       margin-top 0
     .blog-sidebar
       position relative
       &__list-item
         &:after
            content: ''
            border: 1px solid #9b9b9b
            position: absolute
            top: 50%
            width: 14px
            height: 14px
            margin-top: -8px
            right: 15px
            border-radius: 50%
         &:hover
           background-color  #fff
           .blog-sidebar--list-link-header, .blog-sidebar--list-link-content
             color #090909
     .active
        background-color #fff
        .blog-sidebar--list-link-header, .blog-sidebar--list-link-content
          color #090909
        &:before
          content: ''
          position: absolute
          top: 50%
          background: #090909
          width: 10px
          height: 10px
          margin-top: -5px
          right: 18px
          border-radius: 50%

     .blog-item
       width 47.6%
       height auto
   @media screen and (max-width 844px)
     .blog-item
       width 47.4%
   @media screen and (max-width 780px)
     .blog-item
       width 47.3%
   @media screen and (max-width 750px)
     .blog-item
       width 47.1%
     .blog-sidebar
        &__list-link
          padding-right 50px
   @media screen and (max-width 700px)
     .blog-item
       width 47%
   @media screen and (max-width 677px)
     .blog-item
       width 46.6%
   @media screen and (max-width 640px)
     .blog-component__items
       justify-content center
     .blog-item
       max-width 450px
       width 100%