.plan-detail
  margin-bottom: 35px
  &__item
    margin-bottom: 15px
    &:last-child
      margin-bottom: 0
  &__title
    margin-bottom: 5px
    color: $dark-blue
    font-size: 20px
    font-weight: 600
  &__info
    color: $dark-blue
    font-size: 18px
  &__list
    li
      margin-bottom: 5px
      color: $dark-blue
      font-size: 18px
      &:last-child
        margin-bottom: 0

@media(min-width: $sm)
  .plan-detail
    &__item
      display: flex
      margin-bottom: 20px
    &__title
      max-width: 205px
      width: 100%
      margin-right: 20px
      margin-bottom: 0
    &__info
      font-size: 20px
    &__list
      li
        margin-bottom: 20px
        font-size: 20px

@media(min-width: $md)
  .plan-detail
    &__title
      margin-right: 40px