.plan-item
  position: relative
  max-width: 350px
  margin: 0 15px 50px
  text-align: center
  border: 1px solid transparent
  width: 100%
  &.annual
    display: none
  &:last-child
    margin-bottom: 0
  &__content
    position: relative
    overflow: hidden
    margin: 0 -15px
    padding: 30px 15px
    border-radius: 12px
    background-color: $white
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3)
    border: 1px solid black
  &__title
    margin-bottom: 10px
    padding: 0 40px
    font-size: 18px
    font-weight: bold
    align-self: flex-start
  &__subtitle
    margin-bottom: 5px
    padding: 0 30px
    font-size: 22px
    font-weight: 800
    align-self: flex-start
    margin-right: -20px
    &--sub
      font-size: 18px
      vertical-align: text-top
  &__payment_description
    font-size: 16px
    font-weight normal
    padding-left: 10px
  &__filler
    height: 35px
  &__terms
    font-size: 11px
  &__description
    margin-bottom: 40px
    color: $dark-blue
    font-size: 16px
    line-height: 25px
    text-align: start
    &--accent
      color: $red
  &__description-title
    margin-top: 30px
    font-size: 20px
    text-align: start
    min-height: 95px
  &__discount
    position: absolute
    top: 90px
    right: -25px
    width: 200px
    padding: 7px 10px 5px
    color: $white
    font-size: 10px
    transform: rotate(45deg)
    text-transform: uppercase
    transform-origin: 100% 100%
  &__subtext
    margin-top: 20px
    font-size: 16px
    font-weight: 800
  &__active
    display: inline-block
    width: 100%
    height: 52px
    font-size: 16px
    line-height: 52px
    font-weight: bold
    text-transform: uppercase

@media(min-width: $sm)
  .plan-item
    display: flex
    flex-direction: column
    width: calc(33.33% - 50px)
    margin: 0 25px 0
    &.annual
      display: none
    &__content
      display: flex
      flex-direction: column
      flex-grow: 1
    &__description
      flex-grow: 1
    &__subtext
      position: absolute
      left: 0
      right: 0
      bottom: 20px
      margin-top: 0
    &__description-title
      min-height: 95px
    &__subtitle
      padding: 0
      margin-right: 0
    &__title
      padding: 0
    &__payment_description
      font-size: 14px

@media(min-width: $md)
  .plan-item
    &__content
      padding: 45px
    &__title
      margin-bottom: 25px
      padding: 0
      font-size: 20px
    &__subtitle
      margin-bottom: 20px
      padding: 0
      font-size: 22px
      margin-right: -20px
    //&__description
    //  line-height: 45px
    &__discount
      top: 105px
      right: -15px
      font-size: 13px
      font-weight: 800
    &__subtext
      font-size: 22px

@media(min-width: $lg)
  .plan-item
    &__subtitle
      font-size: 30px
      margin-right: -20px
    &__description
      padding: 0 10px
      margin-bottom: 45px
    &__discount
      top: 100px
      right: -10px
      padding: 10px 0 7px 0
