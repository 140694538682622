.checkbox
  display: inline-flex
  align-items: center
  input[type="checkbox"]
    position: absolute
    opacity: 0
    visibility: hidden
    &:checked
      & ~ .checkbox__mark
        background-color: $blue
        &:after
          opacity: 1
  &__mark
    flex: none
    position: relative
    display: block
    width: 20px
    height: 20px
    border-radius: 50%
    border: 1px solid $blue
    cursor: pointer
    transition: .3s
    &:after
      content: ''
      position: absolute
      display: block
      width: 10px
      height: 6px
      margin-top: 3px
      margin-left: 3px
      opacity: 0
      border: 1px solid $white
      border-top: 1px solid transparent
      border-right: 1px solid transparent
      transform:  rotate(-45deg)
      background-color: transparent
      transition: .3s
  &__text
    margin-left: 20px
    color: $dark-blue
    font-size: 16px
    font-weight: normal
    cursor: pointer
    a
      color: $blue
      text-decoration: underline
      &:hover
        text-decoration: none
